import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MachineTabIcon, MyDashBoardIcon } from "../../assets/Icons";
import SimulanisIcon from "../../assets/images/favicon.png";
import { useSelector } from "react-redux";

const SideBar = ({ sideBar, setSideBar }) => {
  const userData = useSelector((state) => state.userSlice);
  const userGroupId = userData?.user?.group?.uuid;

  var location = useLocation();
  var navigate = useNavigate();
  const handleClick = (path, state) => {
    return navigate(path, state);
  };
  const [activeTab, setActiveTab] = useState("machine");
  useEffect(() => {
    var path = location.pathname;
    var active = "machine";
    if (path.startsWith("/dashboard")) active = "dashboard";
    else if (path.startsWith("/machines")) active = "machines";
    setActiveTab(active);
  }, [location.pathname]);

  return (
    <div>
      <div className="mainside">
        <div className={sideBar ? "sidmenu active" : "sidmenu"}>
          <div className="mainsidelist">
            <ul>
              {userData?.user?.permissions?.includes("MACHINE.DASHBOARD") && (
                <li
                  className={`${activeTab == "dashboard" ? "active" : ""}`}
                  onClick={() => handleClick(`/dashboard/${userGroupId}`)}>
                  <MyDashBoardIcon />
                  <p className="ms-3">Dashboard</p>
                </li>
              )}
              {userData?.user?.permissions?.includes("MACHINE.VIEW") && (
                <li
                  className={`${activeTab == "machine" ? "active" : ""}`}
                  onClick={() => handleClick(`/machine-list/view/${userGroupId}`)}>
                  <MachineTabIcon />
                  <p className="ms-3">Machines</p>
                </li>
              )}
            </ul>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <p className="pwrdby">
              {" "}
              Powered by{" "}
              <strong>
                <img src={SimulanisIcon} />
                <span>Simulanis</span>
              </strong>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
