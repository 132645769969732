import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NegativeResponses = ({ questions, loading }) => {

  if (loading) return null;


  return (
    <>
      <div className="machines-list">
        <div className="page-title">
          <h5>
            Top 10 Questions with <span> Negative Responses </span>
          </h5>
        </div>
        <div className="table-responsive machinelist workflowtb mt-3" style={{ minHeight:"auto" }}>
          <table className="table align-middle table-nowrap mb-0">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>Question</th>
                <th>Machine</th>
                <th className="text-center">Negative Responses</th>
                <th className="text-center">Tags</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question, index) => {
                return (
                  <tr key={question.id}>
                    <td className="text-center">{index + 1}</td>
                    <td>{question.title}</td>
                    <td>
                      {" "}
                      <Link to={`/machine-info/${question.machine.uuid}`}>
                        {question.machine.name}
                      </Link>{" "}
                    </td>
                    <td className="text-center">{question.negativeCount}</td>
                    <td className="text-center">
                      <div className="details-tags attable">
                        <span>
                          {question.machine.tags.map((item) => {
                            return <p key={item.id}>{item.name}</p>;
                          })}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default NegativeResponses;
