import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Player, BigPlayButton } from 'video-react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { CloseIcon, PDFIcon, VideoIcon } from '../../assets/Icons';


const Attachments = ({ showAttachments, setShowAttachments, attachment }) => {
  const [activeFile, setActiveFile] = useState({
    type: "image",
    file: ""
  });

  useEffect(() => {
    if (attachment && attachment.length > 0) {
      const file = attachment[0];
      let fileType = file.split('.').pop();
      let type;

      if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
        type = 'image';
      } else if (['mp4', 'mov', 'avi'].includes(fileType)) {
        type = 'video';
      } else if (fileType === 'pdf') {
        type = 'pdf';
      }

      setActiveFile({
        type: type,
        file: file
      });
    }
  }, [attachment]);

  const handleChangeFile = (type, file) => {
    setActiveFile({
      type: type,
      file: file
    });
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <Modal show={showAttachments} size="xl" centered onHide={() => setShowAttachments(false)}>
      <Modal.Header className="modalheader">
        Attachments
        <span onClick={() => setShowAttachments(false)}>
          <CloseIcon />
        </span>
      </Modal.Header>
      <Modal.Body className="attachments m-auto">
        <div className="display-section">
          <div className="Nav-side">
            {attachment?.length && attachment.map((file, index) => {
              let fileType = file.split('.').pop();
              let type;

              if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
                type = 'image';
              } else if (['mp4', 'mov', 'avi'].includes(fileType)) {
                type = 'video';
              } else if (fileType === 'pdf') {
                type = 'pdf';
              }

              return (
                <div key={index} className={activeFile.type === type && activeFile.file === file ? 'option active' : 'option'} onClick={() => handleChangeFile(type, file)}>
                  {type === 'image' && <img src={file} alt="attachment" />}
                  {type === 'video' && <VideoIcon />}
                  {type === 'pdf' && <PDFIcon />}
                </div>
              );
            })}
          </div>
          <div className="Output-side">
            {activeFile.type === "image" && (
              <div className="image-view">
                <img src={activeFile.file} alt="active file" />
              </div>
            )}
            {activeFile.type === "video" && (
              <div className="video-view" onContextMenu={handleContextMenu}>
                <Player>
                  <source src={activeFile.file} />
                  <BigPlayButton position="center" />
                </Player>
              </div>
            )}
            {activeFile.type === "pdf" && (
              <div className="pdf-view">
                <Worker workerUrl="https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={activeFile.file}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </Worker>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Attachments;
