import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import moment from "moment";
import { FilterIcon } from "../../assets/Icons";

function DateRangeFilter({ dates, setDates }) {
  const [mode, setMode] = useState("all");
  const [showPicker, setShowPicker] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    start: dates.start || new Date(),
    end: dates.end || new Date(),
  });

  useEffect(() => {
    document.body.classList.toggle("filter-active", showPicker);
    return () => {
      document.body.classList.remove("filter-active");
    };
  }, [showPicker]);

  const handleAllButtonClick = () => {
    setMode("all");
    setDates({ start: null, end: null });
    setSelectedRange({ start: null, end: null });
    setShowPicker(false);
  };

  const handleApply = () => {
    setMode("custom");
    setDates(selectedRange);
    setShowPicker(false);
  };

  const handleRangeChange = (ranges) => {
    setSelectedRange({
      start: ranges.selection.startDate,
      end: moment(ranges.selection.endDate).endOf('day').toDate(),
    });
  };

  const closeDateModal = () => {
    setShowPicker(false);
  };

  const openDateModal = () => {
    setShowPicker(true);
  };

  return (
    <div className="daterangep mb-0">
      <div className="filteroption m-0">
        <span onClick={openDateModal} >
          {mode === "custom" && selectedRange.start && selectedRange.end ? (
            `${moment(selectedRange.start).format("DD MMM, YY")} - ${moment(
              selectedRange.end
            ).format("DD MMM, YY")}`
          ) : (
            <>
              <FilterIcon/>
              Date
            </>
          )}
        </span>
      </div>

      {showPicker && <div className="showoverlay" onClick={closeDateModal}></div>}

      {showPicker && (
        <div className="daterangepickerf">
          <DateRangePicker
            ranges={[
              {
                startDate: selectedRange.start,
                endDate: selectedRange.end,
                key: "selection",
              },
            ]}
            onChange={handleRangeChange}
            moveRangeOnFirstSelection={false}
            editableDateInputs={true}
            onClose={closeDateModal}
          />

          <div className="d-flex align-items-center justify-content-start gap-2">
            <button
              className="DefaultRedBtn"
              onClick={handleApply}
              style={{ fontSize: "0.75rem" }}
            >
              Apply
            </button>
            <button
              className="DefaultRedBtn"
              onClick={handleAllButtonClick}
              style={{ fontSize: "0.75rem" }}
            >
              All
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DateRangeFilter;
