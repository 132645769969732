import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { CloseIcon } from '../../assets/Icons';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import CustomSelect from '../common/CustomSelect';
import { generateRandomUUID } from '../../utils/util.js';

const AddCheckpoint = ({ addCheckpoint, setAddCheckpoint, fetchQuestions, size, editQuestion, seEditQuestion }) => {
  const { questionTypes, categories } = useSelector(state => state.tagsSlice);
  const configData = useSelector(state => state.configSlice);
  const userData = useSelector(state => state.userSlice);

  const typeOption = questionTypes.map(e => ({
    value: e.name,
    label: e.name,
    categoryId: categories.location,
    id: e.id
  }));

  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [questionType, setQestionType] = useState([]);

  const handleTypeChange = (options) => {
    setQestionType(options)
  };

  let { uuid } = useParams();

  const addCheckpoints = async () => {

    if (!uuid) {
      console.error('Error: Machine ID is required.');
      return;
    }

    if (value.length === 0) {
      setError(' Checkpoint title is required.')
      return;
    }
    setBtnLoading(true)

    try {
      let data = {
        machineId: uuid,
        title: value,
        tags: [questionType.id],
      }

      if (editQuestion.active) {
        await axios.put(`${configData.config.API_URL}/api/machine/questions/update/${editQuestion.data.uuid}`, data, {
          headers: {
            'Authorization': `Bearer ${userData.token}`,
            'Content-Type': 'application/json'
          }
        })
      } else {
        data.options = [
          {
            "uuid": generateRandomUUID(),
            "option": "Yes",
            "isCorrect": true
          },
          {
            "uuid": generateRandomUUID(),
            "option": "No"
          }
        ]
        await axios.post(`${configData.config.API_URL}/api/machine/questions/add`, data, {
          headers: {
            'Authorization': `Bearer ${userData.token}`,
            'Content-Type': 'application/json'
          }
        })
        setValue('');
        setQestionType([])
      }
      seEditQuestion({})
      setAddCheckpoint(false)
      setBtnLoading(false)
    } catch (error) {
      console.log("error", error);
    } finally {
      fetchQuestions()
    }
  }

  useEffect(() => {
    if (editQuestion.active) {
      setValue(editQuestion.data.title)

      setQestionType(editQuestion.data.tags.filter((e) => categories.questionType === e.categoryId).map((item) => {
        return { value: item.name, label: item.name, id: item.id, categoryId: categories.questionType };
      })[0])
    }
  }, [editQuestion.active])
  return (
    <Modal show={addCheckpoint} size="md" centered onHide={() => setAddCheckpoint(false)}>
      <Modal.Header className="modalheader">
        Add New Question
        <span onClick={() => { setAddCheckpoint(false); setValue(""); setQestionType([]); seEditQuestion({}) }}>
          <CloseIcon />
        </span>
      </Modal.Header>
      <Modal.Body className="my-2">
        <div className="row px-3">
          <div className="col-12 standardinput mt-1">
            <label>
              Checkpoint {editQuestion.active ? editQuestion.index + 1 : size + 1}
              <input
                autoComplete="off"
                name="machineName"
                id="machineName"
                className="input form-control"
                type="text"
                value={value}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setValue(newValue);
                  setError(null);
                  seEditQuestion({ ...editQuestion, data: { ...editQuestion.data, title: newValue } });
                }}
              />
              <p className="error">{error}</p>
            </label>
          </div>
          <div className="col-12 standardinput mt-1">
            <div className='forselect-input'>
              <CustomSelect
                option={typeOption}
                handleSelect={handleTypeChange}
                categoryId={categories.questionType}
                label={`Select Question Tag`}
                value={questionType}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="footerbtn">
        <button className='cancel' onClick={() => { setAddCheckpoint(false); setValue(""); setQestionType([]); seEditQuestion({}) }} variant="secondary">
          Cancel
        </button>
        {
          btnLoading ? (
            <button className='loading save'>
              <svg viewBox="25 25 50 50">
                <circle r="20" cy="50" cx="50"></circle>
              </svg>
            </button>
          ) : (
            <button className='save' onClick={addCheckpoints}>Save</button>
          )
        }
      </Modal.Footer>
    </Modal>
  )
}

export default AddCheckpoint
