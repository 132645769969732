import { colors } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import useWindowDimensions from '../../utils/useWindowDimensions';

const MachineDistribution = ({ machineStatus }) => {
    let { width } = useWindowDimensions();
    const chartData = {
        series: [machineStatus.healthyCount, machineStatus.unhealthyCount, machineStatus.noRecordsCount],
        options: {
            chart: {
                type: 'donut',
                stroke: {
                    show: false,
                },
            },
            legend: {
                position: 'bottom',
                show: false
            },
            plotOptions: {
                pie: {
                    donut: {
                        width: '70%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '12px',
                                lineHeight: '1',
                                fontWeight: 'bold',
                            },
                            value: {
                                show: true,
                                fontSize: '12px',
                                lineHeight: '1',
                                fontWeight: 'bold',
                            },
                            total: {
                                show: true,
                                label: 'Total ',
                                fontSize: width > 1700 ? "13" : "10",
                                fontWeight: 'bold',
                            },
                        },
                    },
                },
            },
            stroke: {
                width: 0,
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
            colors: [ '#06D001', '#ff0000', '#5e615a'],
            labels: ['Safe to operate', 'Caution! Trigger Action', "Machine not scanned!"],
        },
    };

    const allMachines = [
        { type: 'Safe to Operate', color: '#06D001', percentage: machineStatus.healthyCount },
        { type: 'Caution! Trigger Action', color: '#ff0000', percentage: machineStatus.unhealthyCount },
        { type: 'Machine not scanned!', color: '#5e615a', percentage: machineStatus.noRecordsCount }
    ];

    return (
        <>
            <div id="chart" className='machine-chart p-1' >
                <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="donut"
                    height={ width > 1700 ? 350 : 250}
                    width={ width > 1700 ? 350 : 250}
                />
                <div className='chart-legends' style={{ display: "flex", flexDirection: "column", alignItems: "start", gap: "10px" }}>
                    {allMachines.map((machine, index) => {
                        return (
                            <p className='machine' key={index}>
                                <span style={{ backgroundColor: machine.color }}>{""}</span>
                                {machine.type}: {machine.percentage} 
                            </p>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default MachineDistribution;