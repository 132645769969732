import React, { useState, useEffect } from "react";
import axios from "axios";
import './App.css';
import { jwtDecode } from "jwt-decode";
import { BrowserRouter as Router } from "react-router-dom";
import jwtEncode from 'jwt-encode';
import FullScreenLoader from './components/loadingComponents/fullScreenLoader';
import DefaultLayout from "./components/layout/DefaultLayout";
import { UNO_URL, applicationId } from "./env.js";
import { saveConfig } from './Store/slice/configSlice';
import { useDispatch, useSelector } from "react-redux";
import { saveUserData, saveChannelUsers } from "./Store/slice/userSlice.js";
import AppRouter from "./components/appRouter.js";
import { saveTags } from "./Store/slice/tagsSlice.js";


function App() {
  const [loading, setLoading] = useState(true);
  const [sideBar, setSideBar] = useState(false);

  const dispatch = useDispatch();

  const getConfiguration = async () => {
    await axios.get(`${UNO_URL}/api/application/configuration/${applicationId}`)
      .then((response) => {
        const data = response.data.data.data;
        dispatch(saveConfig({ data }));
        getUserDetail(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getUserDetail = async (config) => {
    const urlParams = new URLSearchParams(window.location.search);
    const _tokenParam = urlParams.get("referrer");
    let token = "";
    var initialPath = null;
    if (_tokenParam) {
      const decoded = jwtDecode(_tokenParam);
      localStorage.setItem("token", decoded.token);
      token = decoded.token;
      initialPath = decoded.redirectUri;
    } else if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    }

    try {
      let response = await axios.get(`${config.UNO_URL}/api/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      if (response.status === 200) {
        localStorage.setItem("token", response.data.data.token);
        const data = {
          token: response.data.data.token,
          user: response.data.data.user,
        };
        dispatch(saveUserData(data));
        fetchAllTags(config, token)
        getChannelUsersList(config, data.user, data.token);
      }

    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      const payload = {
        origin: window.location.origin,
        redirectUri: window.location.pathname
      };
      const token = jwtEncode(payload, "");
      return (window.location.href = `${config.redirectUri}?referrer=${token}`);
    }
  };

  const getChannelUsersList = async (config, user, token) => {
    try {
      let { data } = await axios.get(`${config.UNO_URL}/api/resource/userListByChannel`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      dispatch(saveChannelUsers(data?.data));
    } catch (error) {
      console.log(error);
    }
  }

  const fetchAllTags = async (config, token) => {
    try {
      let { data } = await axios.get(`${config.API_URL}/api/tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      dispatch(saveTags(data.data))
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    getConfiguration();
  }, []);

  return (
    <>
      {loading ?
        <FullScreenLoader />
        :
        <>
          <Router>
            <DefaultLayout sideBar={sideBar} setSideBar={setSideBar} />
            <div className={sideBar ? "main-content sideactive" : "main-content"} >
              <AppRouter />
            </div>
          </Router>
        </>
      }
    </>
  );
}

export default App;
