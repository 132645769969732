import React from "react";
import LottiePlayer from "lottie-react";
import NoDataFoundAnim from "../assets/gifs/nodatafound.json";

const NoDataFound = ({ heading, nodatatext, dataInfo }) => {
  return (
    <div className="no-data-found">
      <div className="Lottie-parent">
        <LottiePlayer
          animationData={NoDataFoundAnim}
          className="lottie-gif"
          // autoplay = {false}
          // loop = {false}
        />
        <span>
          <h5>{nodatatext}</h5>
          {/* <p> {dataInfo} </p> */}
        </span>
      </div>
    </div>
  );
};

// export pr

export default NoDataFound;
