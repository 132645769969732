import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

const CustomSelect = ({ option, handleSelect, categoryId, label, value }) => {
    const userData = useSelector(state => state.userSlice);
    const configData = useSelector(state => state.configSlice);
    const [options, setOptions] = useState(option || []);

    useEffect(() => {
        if (option) {
            setOptions(option);
        }
    }, [option]);

    const handleChange = async (selected) => {
        if (handleSelect && typeof handleSelect === 'function') {
            handleSelect(selected);
        }
    };

    const handleCreate = async (inputValue) => {
        const newOption = { value: inputValue.toLowerCase(), label: inputValue, categoryId };

        setOptions((prevOptions) => [...prevOptions, newOption]);
        if (handleSelect && typeof handleSelect === 'function') {
           let {data} =  await axios.post(`${configData.config.API_URL}/api/tags/create`,
                { categoryId: newOption.categoryId, name: newOption.value },
                {
                    headers: {
                        'Authorization': `Bearer ${userData.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            newOption.id = data.tagId
            handleSelect(newOption);
        }
    };

    return (
        <div>
            {label && <label className='mb-2'> 
                 <p>
                 {label}  <span>*</span>
                </p> </label>}
            <CreatableSelect
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                    ...theme.colors,
                    primary50 : '#ca373c50',
                    primary25 : '#ca373c50',
                    primary: '#ca373c70',
                    danger :'#000000',
                    neutral30: '#00000050'
                    },
                })}
                isClearable
                value={value}
                options={options}
                onChange={handleChange}
                onCreateOption={handleCreate}
            />
        </div>
    );
};

export default CustomSelect;
