import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchBox from "./common/SearchBox";
import { defaultLogo } from "../env";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getInitials } from "../utils/util";
import NoDataFound from "./NoDataFound";

const filterGroupsOnSearch = (searchString, grpList) =>
  grpList?.filter((item) =>
    item?.name?.toLowerCase().includes(searchString?.trim()?.toLowerCase())
  );

const GroupList = ({ setActiveTab }) => {
  var params = useParams();
  const navigate = useNavigate();
  var { groupId } = params;
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const [groupsList, setGroupsList] = useState([]);
  const [search, setSearch] = useState("");
  const filteredGroupList = search ? filterGroupsOnSearch(search, groupsList) : groupsList;

  var fetchGroups = async () => {
    try {
      // setGroupLength(0)
      var { data } = await axios.get(
        `${configData.config.UNO_URL}/api/user/group/list/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );

      setGroupsList(data.data);
      // setGroupLength(data.data.length)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, [groupId]);

  const groupClickHandler = (grpId) => {
    setActiveTab("Dashboard");
    navigate(`/dashboard/${grpId}`);
  };

  return (
    <>
      <div className="row pt-2 mt-1">
        <div className="col-md-12 d-flex align-items-center justify-content-end">
          <SearchBox search={search} setSearch={setSearch} />
        </div>
      </div>
      <div className="courses-parent usergrouplist">
        <div className="course-tiles mt-0">
          { filteredGroupList?.length > 0 ? 
          <>        
          {filteredGroupList?.map((group) => {
            return group?.avatar ? (
              <div
                className="tilecard p-0 mb-3"
                key={group?.uuid}
                onClick={() => groupClickHandler(group?.uuid)}>
                <div className="groupcardesign">
                  <div className="avatarimg">
                    <img src={defaultLogo} alt="" title="Simulanis" />
                  </div>
                  <h6 className="groupname">{group?.name}</h6>
                  <div className="groupusercount">
                    <p>
                      {group?.groups} Sub-Groups <span className="mx-1">|</span>
                    </p>
                    <p> {group?.users} Users</p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="tilecard p-0 mb-3"
                key={group?.uuid}
                onClick={() => groupClickHandler(group?.uuid)}>
                <div className="groupcardesign">
                  <div className="initials-placeholder">{getInitials(group?.name)}</div>
                  <h6 className="groupname">{group?.name}</h6>
                  <div className="groupusercount">
                    <p>
                      {group?.groups} Sub-Groups <span className="mx-1">|</span>
                    </p>
                    <p> {group?.users} Users</p>
                  </div>
                </div>
              </div>
            );
          })}
          </> :
           <div className='d-flex align-items-center justify-content-center w-100'>
           <NoDataFound nodatatext={"No Groups Found"}/>
           </div>
          }

        </div>
      </div>
    </>
  );
};

export default GroupList;
