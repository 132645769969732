import * as XLSX from "xlsx";
import moment from "moment";

export const generateExcel = (name, tableData, questionLength, headers) => {
  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([[]]);
  const mergeCells = () => {
    const merge = [];
    let end = null;
    for (let i = 0; i < questionLength; i++) {
      const start = i === 0 ? 3 + i + 1 : end + 1;
      end = start + 1;
      merge.push({
        s: {
          c: start,
          r: 0,
        },
        e: {
          c: end,
          r: 0,
        },
      });
    }
    return merge;
  };
  ws["!merges"] = mergeCells();
  const worksheet = XLSX.utils.sheet_add_json(ws, tableData);
  XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });


  XLSX.utils.book_append_sheet(workbook, worksheet, `${name}`);

  XLSX.writeFile(workbook, `${name}_${moment().format("MMMM Do YYYY, h:mm:ss a")}.xlsx`);
};
