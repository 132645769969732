import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddImageIcon, BackIcon, CancelIcon, CloseIcon } from '../assets/Icons';
import Groups from './Groups';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import CustomSelect from './common/CustomSelect';
import axios from 'axios';
import { extractData } from '../utils/util';

// Helper function to validate UUID
const isValidUUID = (uuid) => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(uuid);

const CreateMachine = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const { groups } = useSelector((state) => state.groupSlice);
  const { locations, makes, categories } = useSelector((state) => state.tagsSlice);
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const { getRootProps, getInputProps , acceptedFiles} = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png']
    },
    multiple: false,
  });

  const [search, setSearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [make, setMake] = useState(null);
  const [location, setLocation] = useState(null);
  const [machineId, setMachineId] = useState('');
  const [machineName, setMachineName] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadedIcon, setUploadedIcon] = useState(null);
  const [machineImage, setMachineImage] = useState(null);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [ParentGroupId, setParentGroupId] = useState(null);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showList, setShowList] = useState(false);

  useEffect(()=>{
    setUserList(userData.channelUsers);
  },[userData.channelUsers])

  const fetchMachineDetails = useCallback(async (type) => {
    try {
      const { data } = await axios.get(`${configData.config.API_URL}/api/machine/details/${type}`, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
          'Content-Type': 'application/json',
        },
      });

      let groupIds = data.data.groups;

      const { name, data: machineData, tags, email} = data.data;

      let taggedUser = email.map(e => e.uuid );
      let selectedUsers = userData.channelUsers.filter((user) => taggedUser.includes(user.uuid));
      let unSelectedUsers = userData.channelUsers.filter((user) => !taggedUser.includes(user.uuid))
      setSelectedUsers(selectedUsers);
      setUserList(unSelectedUsers);
      setMachineName(name);
      setMachineId(machineData.machineId);
      setSerialNumber(machineData.serialNo);
      setMachineImage(data.data.thumbnail || null);
      if (data.data.groups) {
        const groupResponse = await axios.post(`${configData.config.UNO_URL}/api/user/bulk/group/details`, { groupIds: groupIds }, {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            'Content-Type': 'application/json',
          },
        });

        let filterParentGroup = groupResponse.data.data.filter(e =>e.uuid !== userData.user.group.uuid)

        setSelectedGroups(filterParentGroup)
      }

      const makeTag = tags.find((tag) => tag.categoryId === categories.make);
      const locationTag = tags.find((tag) => tag.categoryId === categories.location);

      setMake(makeTag ? { value: makeTag.name, label: makeTag.name, id: makeTag.id } : null);
      setLocation(locationTag ? { value: locationTag.name, label: locationTag.name, id: locationTag.id } : null);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching machine details:", error);
      setLoading(false);
    }
  }, [categories.make, categories.location, configData.config.API_URL, userData.token]);

  const handleChange = (setter) => (e) => {
    setError((prevError) => ({ ...prevError, [e.target.name]: null }));
    setter(e.target.value);
  };

  const handleSelectChange = (setter) => (option) => {
    setError((prevError) => ({ ...prevError, [option.name]: null }));
    setter(option);
  };

  useEffect(() => {
    if (acceptedFiles.length)
      {
        const file = acceptedFiles[0];
        setUploadFile(file);
    
        const reader = new FileReader();
        reader.onload = () => setUploadedIcon(reader.result);
        reader.readAsDataURL(file);
      }
  
  }, [acceptedFiles]);

  // Setup dropzone configuration
 
  const handleCancelUpload = (event) => {
    event.stopPropagation();
    setUploadedIcon(null);
    setMachineImage(null);
  };

  // Handle save machine data
  const handleSave = async () => {
    const errors = {
      machineName: machineName ? null : 'Machine name is required',
      machineId: machineId ? null : 'Model number is required',
      make: make ? null : 'Make is required',
      location: location ? null : 'Location is required',
    };

    if (Object.values(errors).some((error) => error !== null)) {
      setError(errors);
      return;
    }

    setLoading(true);

    const machineData = {
      name: machineName,
      machineId,
      serialNo: serialNumber,
      tags: [make.id, location.id],
      groups: selectedGroups.map(e => e.uuid),
      users: selectedUsers.map(e => e.uuid)
    };

    machineData.groups.push(ParentGroupId)

    try {
      if (isValidUUID(type)) {
        await updateMachine(machineData, uploadFile, type);
      } else {
        await createMachine(machineData, uploadFile);
      }
      resetForm();
    } catch (error) {
      console.error("Error saving machine:", error);
      setLoading(false);
    }
  };

  // Update machine details
  const updateMachine = async (machineData, file, uuid) => {
    setProcessing(true);
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
      const { data } = await axios.post(
        `${configData.config.UNO_URL}/api/resource/machine-monitoring/attachment/${uuid}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      machineData.thumbnail = data.url;
    }

    await axios.put(`${configData.config.API_URL}/api/machine/update/${uuid}`, machineData, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
        'Content-Type': 'application/json',
      },
    });

    setProcessing(false);
    navigate(`/machine-info/${type}`);
  };

  const createMachine = async (machineData, file) => {
    setProcessing(true);

    const { data: responseData } = await axios.post(
      `${configData.config.API_URL}/api/machine/add`, machineData, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
        'Content-Type': 'application/json',
      },
    }
    );

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const { data: fileData } = await axios.post(
        `${configData.config.UNO_URL}/api/resource/machine-monitoring/attachment/${responseData.machineId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      machineData.thumbnail = fileData.url;

      await axios.put(`${configData.config.API_URL}/api/machine/update/${responseData.machineId}`, machineData, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
          'Content-Type': 'application/json',
        },
      });
    }

    setProcessing(false);
    navigate(`/machine-list`);
  };

  const resetForm = () => {
    setMake(null);
    setLocation(null);
    setMachineId('');
    setMachineName('');
    setSerialNumber('');
    setMachineImage(null);
    setUploadedIcon(null);
    setSelectedGroups([]);
    setError({});
  };

  useEffect(() => {
    if (isValidUUID(type)) {
      fetchMachineDetails(type);
    } else {
      setLoading(false);
    }
  }, [type, fetchMachineDetails]);

  const handleClickSelectUSer = (user, index) =>{
    let _userList = [...userList];
    _userList.splice(index, 1);
    setUserList(_userList);
    setSelectedUsers((pre) => [...pre, user]);
    setNameSearch("");
  }
  const handleClickCancelUSer = (user, index) =>{
    let _userList = [...userList, user];
    setUserList(_userList);
    let _selectedUsers = [...selectedUsers];
    _selectedUsers.splice(index, 1);
    setSelectedUsers(_selectedUsers);
  }

  return (
    <div className="container-fluid p-4 pt-2">
      <button className="backbtn cursor-pointer mt-2" onClick={() => navigate(-1)} title="Back">
        <BackIcon /> Back
      </button>
      <div className="add-info">

        <div className="row">
          <div className="col-lg-6">
            <div className="image-formbox mt-3">
              <div {...getRootProps()} className="dropbox">
                <input {...getInputProps()} />
                {uploadedIcon ? (
                  <div className="uploaded-container">
                    <img src={uploadedIcon} alt="Uploaded" className="uploaded-image" />
                    <button onClick={handleCancelUpload} className="cancel-button">
                      <CancelIcon />
                    </button>
                  </div>
                ) : machineImage ? (
                  <div className="uploaded-container">
                    <img src={machineImage} alt="Uploaded" className="uploaded-image" />
                    <button onClick={handleCancelUpload} className="cancel-button">
                      <CancelIcon />
                    </button>
                  </div>
                ) : (
                  <div className="placeholder">
                    <AddImageIcon />
                  </div>
                )}
              </div>
              <div className="d-flex align-items-start flex-column justify-content-start">
                <p>Logo</p>
                <p className="little-info">Recommended Format* PNG</p>
              </div>
            </div>
            <div className="col-12 standardinput mt-3">
              <label className="mb-2">
                <p>
                  Machine Name <span>*</span>
                </p>
              </label>
              <input
                autoComplete="off"
                name="machineName"
                id="machineName"
                className="input form-control"
                type="text"
                value={machineName}
                onChange={handleChange(setMachineName)}
              />
              <p className="error">{error.machineName}</p>
            </div>
            <div className="col-12 standardinput">
              <label>
                <p>
                  Machine Id <span>*</span>
                </p>
              </label>
              <input
                autoComplete="off"
                name="machineId"
                id="machineId"
                className="input form-control"
                type="text"
                value={machineId}
                onChange={handleChange(setMachineId)}
              />
              <p className="error">{error.machineId}</p>
            </div>
          </div>
          <div className="col-lg-6 mt-4">
            <div className="col-12 standardinput mt-3">
              <label className="mb-2">
                <p>Serial Number</p>
              </label>
              <input
                autoComplete="off"
                name="serialNumber"
                id="serialNumber"
                className="input form-control"
                type="text"
                value={serialNumber}
                onChange={handleChange(setSerialNumber)}
              />
            </div>
            <div className="col-12 standardinput mt-3">
              <div className="forselect-input">
                <CustomSelect
                  option={makes.map((e) => ({
                    value: e.name,
                    label: e.name,
                    id: e.id,
                  }))}
                  handleSelect={handleSelectChange(setMake)}
                  categoryId={categories.make}
                  label="Select Make"
                  value={make}
                />
              </div>
              <p className="error">{error.make}</p>
            </div>
            <div className="col-12 standardinput">
              <div className="forselect-input">
                <CustomSelect
                  option={locations.map((e) => ({
                    value: e.name,
                    label: e.name,
                    id: e.id,
                  }))}
                  handleSelect={handleSelectChange(setLocation)}
                  categoryId={categories.location}
                  label="Select Location"
                  value={location}
                />
              </div>
              <p className="error">{error.location}</p>
            </div>
          </div>
        </div>


        <div className="col-md-12">
                  <div className="quesformbox mt-3">
                    <p style={{ fontWeight: "500" }}>Users to be notified</p>
                    <div className="UserBox">
                      <div className="rolelist">
                        {selectedUsers &&
                          selectedUsers.map((user, index) => {
                            return (
                              <div key={index + 1} className="userselect">
                                {user.avatar ? (
                                  <img src={user.avatar} referrerPolicy={"no-referrer"} alt="" />
                                ) : (
                                  <span className="sm-initials">
                                    {user.name.length > 0
                                      ? user.name.split(" ").length >= 2
                                        ? `${user.name.split(" ")[0][0]}${
                                            user.name.split(" ")[1][0]
                                          }`
                                        : `${user.name.split(" ")[0][0]}`
                                      : ""}
                                  </span>
                                )}
                                <p>{user.name}</p>
                                <span
                                  onClick={() => handleClickCancelUSer(user, index)}
                                  className="removeuser">
                                  <CloseIcon />
                                </span>
                              </div>
                            );
                          })}
                        <div className="userselect withinput">
                          <input
                            type="text"
                            placeholder="Add Users"
                            className="form-control"
                            value={nameSearch}
                            onChange={(e) => setNameSearch(e.target.value)}
                            onFocus={() => setShowList(true)}
                            onBlur={() => setTimeout(() => setShowList(false), 500)}
                          />
                          {showList ? (
                            <div className="userinputsearch">
                              {userList.map((user, index) => {
                                if (
                                  nameSearch &&
                                  !user.name.toLowerCase().includes(nameSearch.toLowerCase())
                                )
                                  return null;
                                return (
                                  <div
                                    key={index + 1}
                                    className="userserachitem "
                                    onClick={() => handleClickSelectUSer(user, index)}>
                                    {user.avatar ? (
                                      <img
                                        src={user.avatar}
                                        alt=""
                                        referrerPolicy={"no-referrer"}
                                      />
                                    ) : (
                                      <span className="sm-initials">
                                        {user.name.length > 0
                                          ? user.name.split(" ").length >= 2
                                            ? `${user.name.split(" ")[0][0]}${
                                                user.name.split(" ")[1][0]
                                              }`
                                            : `${user.name.split(" ")[0][0]}`
                                          : ""}
                                      </span>
                                    )}
                                    <p>{user.name}</p>
                                  </div>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
        </div>


        <div className="col-12">
          <Groups
            setSearch={setSearch}
            search={search}
            groups={groups}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            setParentGroupId={setParentGroupId}
            ParentGroupId={ParentGroupId}
          />
        </div>




        <div className="footerbtn mt-3">
          <button className="cancel" onClick={resetForm}>
            Cancel
          </button>
          {processing ? (
            <button className="loading save">
              <svg viewBox="25 25 50 50">
                <circle r="20" cy="50" cx="50"></circle>
              </svg>
            </button>
          ) : (
            <button className="save" onClick={handleSave}>
              Save
            </button>
          )}
        </div>


      </div>
    </div>
  );
};

export default CreateMachine;
