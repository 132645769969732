import React from 'react';
import PropTypes from 'prop-types';
import LottiePlayer from "lottie-react";
import loadingAnimation from './loading.json';



const FullScreenLoader  = ({ loading = true }) => {
    if (!loading) return null;
    return (

        <div style={{ 
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            position : 'fixed',
            overflow : 'hidden',
         }}>
            <LottiePlayer
                animationData={loadingAnimation}
                style={{ height: 250 }}
                autoplay
                loop
            />
        </div>
    )
};

FullScreenLoader.propTypes = {
   loading : PropTypes.bool
  };

export default FullScreenLoader;