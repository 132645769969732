import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/CSS/style.css';
import '../src/assets/CSS/responsive.css';
import 'video-react/dist/video-react.css';
import 'react-loading-skeleton/dist/skeleton.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import {store} from "./Store/Store"
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store} >
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </Provider>
);

reportWebVitals();
