import { configureStore } from "@reduxjs/toolkit";
import configSlice from "./slice/configSlice";
import userSlice from "./slice/userSlice";
import tagsSlice from "./slice/tagsSlice";
import applicationSlice from "./slice/applicationSlice";
import groupSlice from "./slice/groupSlice";

export const store = configureStore({
    reducer: {
        configSlice,
        userSlice,
        tagsSlice,
        applicationSlice,
        groupSlice
    }
})