import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { getInitials } from "../utils/util";
import moment from "moment/moment";
import axios from "axios";
import { useSelector } from "react-redux";

const ResponseList = ({ setResponseLenght, setUserResponse }) => {
  const { uuid } = useParams();
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const [loading, setLoading] = useState(true);
  const [responses, setRespnses] = useState([]);
  const fetchResponses = async () => {
    try {
      let { data } = await axios.get(
        `${configData.config.API_URL}/api/machine/quiz/responses/${uuid}`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setResponseLenght(data.data.length);
      setRespnses(data.data);
      setUserResponse(data?.data)
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResponses();
  }, [uuid]);
  return (
    <>
      {loading ? (
        <div className="table-responsive workflowtb mt-2">
          <table className="table align-middle table-nowrap mb-0">
            <thead>
              <tr>
                <th className="text-center">S.No.</th>
                <th>User</th>
                <th className="text-center">Date & Time</th>
                <th className="text-center">Health Status</th>
                <th className="text-center">Response</th>
              </tr>
            </thead>
            <tbody>
               { Array.from({length:3}).map((_,index)=>{
                return(
                      <tr>
                        <td className="text-center"> <Skeleton width="3vw" height="1.5rem" /> </td>
                        <td>
                          <div className="nameemail">
                            <Skeleton width="3rem" height="3rem" circle />
                            <span>   
                                <p> <Skeleton width="8vw" height="1.4rem" /> </p>
                                <p> <Skeleton width="15vw" height="1.15rem" /> </p>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="datetime">
                            <p> <Skeleton width="8vw" height="1.4rem" />
                            </p>
                            <p>
                              <Skeleton width="3vw" height="1.15rem" />
                            </p>
                          </div>
                        </td>
                        <td className="text-center">
                          <Skeleton width="8vw" height="1.5rem" />
                        </td>
                        <td className="text-center">
                          <Skeleton width="3vw" height="1.5rem" />
                        </td>
                      </tr>
                )
               }) }

            </tbody>
          </table>
        </div>
      ) : (
        <>
          {responses.length > 0 ? (
            <>
              <div className="table-responsive workflowtb mt-2">
                <table className="table align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th className="text-center">S.No.</th>
                      <th>User</th>
                      <th className="text-center">Date & Time</th>
                      <th className="text-center">Health Status</th>
                      <th className="text-center">Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses.map((response, index) => {
                      return (
                        <tr key={index + 1}>
                          <td className="text-center"> {index + 1} </td>
                          <td>
                            <div className="nameemail">
                              {response?.avatar?.length ? (
                                <img
                                  src={response.avatar}
                                  alt={response.name || "Profile Image"}
                                  title={response.name || ""}
                                  className="profilepik"
                                  referrerPolicy={"no-referrer"}
                                />
                              ) : (
                                <div className="avatar-placeholder">
                                  {getInitials(response.name).toUpperCase()}
                                </div>
                              )}
                              <span>
                                <p>{response.name}</p>
                                <p>{response.email}</p>
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="datetime">
                              <p>{moment(response.createdAt).format("DD-MM-YYYY")}</p>
                              <p>{moment(response.createdAt).format("HH:mm")}</p>
                            </div>
                          </td>

                          {response.health === 100 ? (
                            <td className="text-center good">
                              <span className="good">{Math.abs(response.health).toFixed(2)}%</span>
                            </td>
                          ) : (
                            <td className="text-center bad">
                              <span className="bad">{Math.abs(response.health).toFixed(2)}%</span>
                            </td>
                          )}

                          <td className="text-center">
                            <Link to={`/user-response/${response.uuid}/${response.machineId}`}>
                              View
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="px-4">
                <div className="first-event">No Response received yet!</div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ResponseList;
