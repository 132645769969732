import { v4 as uuidv4 } from 'uuid';

export const getInitials = (str) => {
    if (typeof str !== 'string') {
        return '';
    }
    let words = str.split(' ');
    let initials = words.slice(0, 2).map(word => word.charAt(0).toUpperCase()).join('');
    return initials;
}


export const extractData = (data) => {
    const { uuid, name, avatar, children } = data;
    const result = { uuid, name, avatar };

    if (children && children.length > 0) {
        result.children = children.map(child => extractData(child));
    }

    return result;
}


export const generateRandomUUID = () => {
    return uuidv4();
}