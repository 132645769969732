import React, { useState } from 'react'
import { CancelIcon, SearchesCIcon } from '../../assets/Icons'

const SearchBox = ({ search, setSearch }) => {
  return (
    <div>
      <form className=" app-search app-search1 d-flex justify-content-start ">
        <div className="position-relative">
          <SearchesCIcon />
          {search.length > 0 &&
            <span className='searchiconcancel' onClick={() => setSearch("")} >
              <CancelIcon />
            </span>
          }
          <input
            type="text"
            name="searchName"
            className="form-control"
            placeholder="Search"
            autoComplete="off"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </form>
    </div>
  )
}

export default SearchBox
