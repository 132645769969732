import axios from 'axios';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


const DeleteItem = ({ setSelected, selected, deleteCheckpoint, fetchQuestions, setDeleteCheckpoint, deleteSingle }) => {
  const userData = useSelector(state => state.userSlice);
  const configData = useSelector(state => state.configSlice);

  const { uuid } = useParams();

  const deleteQuestions = async () => {
    try {
      if (deleteSingle) {
        await axios.delete(
          `${configData.config.API_URL}/api/machine/questions/delete/${uuid}/${selected[0]}`,
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        await axios.delete(
          `${configData.config.API_URL}/api/machine/questions/deleteMultiple/${uuid}`,
          {
            data: selected,
            headers: {
              Authorization: `Bearer ${userData.token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setSelected([]);
      fetchQuestions();
      setDeleteCheckpoint(false);
    }
  };


  return (
    <Modal centered show={deleteCheckpoint} onHide={() => setDeleteCheckpoint(false)}>
      <Modal.Header className="popupheader delete pt-4">
        Delete Question
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group pt-4 pb-5 m-0">
              <p className="popupheading delete p-0 m-0">{`Do you really want to delete selected ${deleteSingle ? "Question" : "Questions"}`}</p>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center mt-2 mb-2 footerbtn">
                <button className='cancel' onClick={() => setDeleteCheckpoint(false)}>Cancel</button>
                <button className="save" onClick={deleteQuestions}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteItem
