import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AddImageIcon, CancelIcon, CloseIcon } from '../../assets/Icons';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from '../common/CustomSelect';
import axios from 'axios';
import { saveTags } from '../../Store/slice/tagsSlice';
import NestedMultiSelect from '../common/NestedMultiSelect';

const AddItem = ({ addMachine, setAddMachine, onSaveMachine, machineDetails, editMode, loadingBtn, setLoadingBtn }) => {
  const { locations, makes, categories } = useSelector(state => state.tagsSlice);
  const locationOptions = locations.map(e => ({
    value: e.name,
    label: e.name,
    categoryId: categories.location,
    id: e.id
  }));

  const makeOptions = makes.map(e => ({
    value: e.name,
    label: e.name,
    categoryId: categories.make,
    id: e.id
  }));

  const [make, setMake] = useState([]);
  const [error, setError] = useState({});
  const [location, setLocation] = useState([]);
  const [machineName, setMachineName] = useState('');
  const [machineId, setMachineId] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [machineImage, setMachineImage] = useState(null);


  const handleMachineNameChange = (e) => {
    setError({ ...error, name: null });
    setMachineName(e.target.value);
  };

  const handleModelIdChange = (e) => {
    setError({ ...error, machineId: null });
    setMachineId(e.target.value);
  };

  const handleSerialNumberChange = (e) => {
    setSerialNumber(e.target.value);
  };

  const handleMakeChange = (options) => {
    setMake(options)
  };

  const handleLocationChange = (options) => {
    setLocation(options);
  };

  const handleSave = async () => {
    const errors = {
      name: machineName ? null : 'Machine name is required',
      machineId: machineId ? null : 'Model number is required',
      make: make.value ? null : 'Make is required',
      location: location.value ? null : 'Location is required',
    };

    if (Object.values(errors).some(error => error !== null)) {
      setError(errors);
      return;
    }

    setLoadingBtn(true);

    let data = {
      name: machineName,
      machineId: machineId,
      serialNo: serialNumber,
      tags: [make.id, location.id]
    }

    setMake('');
    setLocation('');
    setMachineId('');
    setMachineName('');
    setSerialNumber('');
    setMachineImage(null);
    setUploadedIcon(null)
    onSaveMachine(data, uploadFile)
  };

  const [uploadedIcon, setUploadedIcon] = useState(null);


  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    setUploadFile(file)

    const reader = new FileReader();

    reader.onload = () => {
      setUploadedIcon(reader.result);
    };

    reader.readAsDataURL(file);
  }, []);


  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const handleCancelUpload = (event) => {
    event.stopPropagation();
    setUploadedIcon(null);
    setMachineImage(null)
  };


  useEffect(() => {
    if (editMode) {
      if (machineDetails.thumbnail) setMachineImage(machineDetails.thumbnail)
      setMachineName(machineDetails.name);
      setMachineId(machineDetails.data.machineId);
      setSerialNumber(machineDetails.data.serialNo);
      setMake(machineDetails.tags.filter((e) => categories.make === e.categoryId).map((item) => {
        return { value: item.name, label: item.name, id: item.id, categoryId: categories.make };
      })[0])
      setLocation(machineDetails.tags.filter((e) => categories.location === e.categoryId).map((item) => {
        return { value: item.name, label: item.name, id: item.id, categoryId: categories.make };
      })[0])
    }
  }, [editMode]);
  return (
    <Modal show={addMachine} size="lg" centered onHide={() => setAddMachine(false)}>
      <Modal.Header className="modalheader">
        {
          editMode ? "Update Machine" : "Add New Machine"
        }
        <span onClick={() => { setAddMachine(false); setError({}) }}>
          <CloseIcon />
        </span>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="row px-4">
          <div className="image-formbox mt-3">
            <div {...getRootProps()} className="dropbox">
              <input {...getInputProps()} />
              {uploadedIcon ? (
                <div className="uploaded-container">
                  <img src={uploadedIcon} alt="Uploaded" className="uploaded-image" />
                  <button onClick={handleCancelUpload} className="cancel-button">
                    <CancelIcon />
                  </button>
                </div>
              ) : (
                <>
                  {
                    machineImage ? (<div className="uploaded-container">
                      <img src={machineImage} alt="Uploaded" className="uploaded-image" />
                      <button onClick={handleCancelUpload} className="cancel-button">
                        <CancelIcon />
                      </button>
                    </div>) : (
                      <div className="placeholder">
                        <AddImageIcon />
                      </div>
                    )
                  }
                </>
              )}
            </div>
            <div className="d-flex align-items-start flex-column justify-content-start">
              <p> Logo</p>
              <p className="little-info">Recommended Format* PNG</p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className="col-12 standardinput mt-3">
              <label className='mb-2'>
                <p>
                  Machine Name <span>*</span>
                </p>
              </label>
              <input
                autoComplete="off"
                name="machineName"
                id="machineName"
                className="input form-control"
                type="text"
                value={machineName}
                onChange={handleMachineNameChange}
              />
              <p className="error">{error.name}</p>
            </div>
            <div className="col-12 standardinput">
              <label className='mb-2'>
                <p>
                  Machine Id  <span>*</span>
                </p>
              </label>
              <input
                autoComplete="off"
                name="machineId"
                id="machineId"
                className="input form-control"
                type="text"
                value={machineId}
                onChange={handleModelIdChange}
              />
              <p className="error">{error.machineId}</p>

            </div>
            <div className="col-12 standardinput">
              <label className='mb-2'>
                <p>
                  Serial Number
                </p>
              </label>
              <input
                autoComplete="off"
                name="serialNumber"
                id="serialNumber"
                className="input form-control"
                type="text"
                value={serialNumber}
                onChange={handleSerialNumberChange}
              />

            </div>

          </div>
          <div className='col-lg-6 mt-0'>

            <div className="col-12 standardinput mt-3">
              <div className='forselect-input'>
                <CustomSelect
                  option={makeOptions}
                  handleSelect={handleMakeChange}
                  categoryId={categories.make}
                  label={`Select Make`}
                  value={make}
                />
              </div>
              <p className="error">{error.make}</p>
            </div>
            <div className="col-12 standardinput">
              <div className='forselect-input'>
                <CustomSelect
                  option={locationOptions}
                  handleSelect={handleLocationChange}
                  categoryId={categories.location}
                  label={`Select Location`}
                  value={location}
                />
              </div>
              <p className="error">{error.location}</p>
            </div>
            
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="footerbtn">
        <button className='cancel' variant="secondary" onClick={() => {
          setMachineImage(null)
          setUploadedIcon(null)
          setAddMachine(false);
          setMachineName('');
          setMachineId('');
          setSerialNumber('');
          setMake('');
          setLocation('');
          setError({
            name: null,
            machineId: null,
            serialNo: null,
          });
        }}>
          Cancel
        </button>
        {
          loadingBtn ? (<button className='loading save'>
            <svg viewBox="25 25 50 50">
              <circle r="20" cy="50" cx="50"></circle>
            </svg>
          </button>)
            :
            (<button className='save' onClick={handleSave}>Save</button>)
        }

      </Modal.Footer>
    </Modal>
  );
};

export default AddItem;