import React, { useEffect, useState } from "react";
import DateRangeSelector from "./common/DateRangeSelector";
import DateRangeFilter from "./common/DateRangeFilter";
import { Nav, Tab } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import EmergencyIcon from "../assets/images/emergency.png";
import MechanicalIcon from "../assets/images/system.png";
import GuardingIcon from "../assets/images/shield.png";
import ElectricalIcon from "../assets/images/flash.png";
import { BackIcon, CloseIcon } from "../assets/Icons";
import MachineChart from "./Charts/MachineDistribution";
import { useSelector } from "react-redux";
import { getInitials } from "../utils/util";
import Skeleton from "react-loading-skeleton";
import ResponseChart from "./Charts/ResponseChart";
import axios from "axios";
import NegativeResponses from "./NegativeResponses";
import GroupList from "./GroupList";
import { useNavigate, useParams } from "react-router-dom";
import apiClient from "../apiClient";
import GroupWiseReport from "./GroupWiseReport";

const Dashboard = () => {
  const userData = useSelector((state) => state.userSlice);
  const navigate = useNavigate();
  const { groupId } = useParams();
  const configData = useSelector((state) => state.configSlice);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const [tagStats, setTagStats] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [machineStatus, setMachineStatus] = useState({
    healthyCount: 0,
    unhealthyCount: 0,
    noRecordsCount: 0,
  });
  const [machineResponseStats, setMachineResponseStats] = useState([]);
  const [options, setOptions] = useState([]);
  const [machineId, setMachineId] = useState([]);
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [groupInfo, setGroupInfo] = useState(null);
  const [dates, setDates] = useState({ start: null, end: null });
  const [list, setList] = useState([]);
  const [machineScans, setMachineScans] = useState({});

  const fetchTagStats = async () => {
    try {
      const { data } = await axios.post(
        `${configData.config.API_URL}/api/dashboard/report/tags/stats/${groupId}`,
        {
          machineIds: machineId.map((item) => item.code),
          type: "questionType",
        },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const tagStats = data.data.map((e) => {
        switch (e.tag) {
          case "electrical":
            e.icon = ElectricalIcon;
            break;
          case "mechanical":
            e.icon = MechanicalIcon;
            break;
          case "guarding":
            e.icon = GuardingIcon;
            break;
          case "emergency":
            e.icon = EmergencyIcon;
            break;
          default:
            break;
        }
        return e;
      });

      setTagStats(tagStats);
    } catch (error) {
      console.error("Error fetching tag stats:", error);
    }
  };

  const fetchMachineStats = async () => {
    try {
      const { data } = await axios.post(
        `${configData.config.API_URL}/api/dashboard/report/machine/stats/${groupId}`,
        {
          machineIds: machineId.map((item) => item.code),
          dates: {
            start: dates.start ? dates.start.toISOString() : null,
            end: dates.start ? dates.end.toISOString() : null,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const machineStats = data.data;
      setMachineStatus(machineStats);
    } catch (error) {
      console.error("Error fetching machine stats:", error);
    }
  };

  const fetchMachineList = async () => {
    try {
      const { data } = await axios.get(`${configData.config.API_URL}/api/dashboard/report/list/machine/${groupId}`, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
          "Content-Type": "application/json",
        },
      });
      const machineList = data.data.map((item) => ({
        name: item?.label,
        code: item?.value,
      }));
      setOptions(machineList);
    } catch (error) {
      console.error("Error fetching machine list:", error);
    }
  };

  const fetchResponseStats = async () => {
    try {
      const _bodyData = {
        machineIds: machineId.map((item) => item.code),
        dates: {
          start: dates.start ? dates.start.toISOString() : null,
          end: dates.start ? dates.end.toISOString() : null,
        },
      };
      const { data } = await axios.post(
        `${configData.config.API_URL}/api/dashboard/report/response/stats/${groupId}`,
        _bodyData,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const list = data.data;
      setMachineResponseStats(list);
    } catch (error) {
      console.error("Error fetching response stats:", error);
    }
  };

  const handleSortMachineList = (e) => {
    setMachineId(e.value);
  };

  const fetchGroupDetail = async () => {
    setGroupInfo(null);
    const res = await apiClient.get(`${configData.config.UNO_URL}/api/user/group/details/${groupId}`, {
      headers: {
        Authorization: `Bearer ${userData?.token}`,
      },
    });
    res ? setGroupInfo(res) : setGroupInfo({});
  };

  const fetchNegativeResponses = async () => {
    try {
      const { data } = await axios.post(
        `${configData.config.API_URL}/api/dashboard/report/negative/responses/${groupId}`,
        {
          machineIds: machineId.map((item) => item.code),
          dates: {
            start: dates.start ? dates.start.toISOString() : null,
            end: dates.start ? dates.end.toISOString() : null,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setQuestions(data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchNegativeResponsesGroupwise = async () => {
    try {
      const { data } = await axios.post(
        `${configData.config.API_URL}/api/dashboard/report/negative/responses/byGroup/${groupId}`,
        {
          machineIds: machineId.map((item) => item.code),
          dates: {
            start: dates.start ? dates.start.toISOString() : null,
            end: dates.start ? dates.end.toISOString() : null,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchMachineScans = async () => {
    const queryParams = {};
    if (dates.start && dates.end) {
      queryParams.start = dates.start.toISOString();
      queryParams.end = dates.end.toISOString();
    }
    if (machineId.length > 0) {
      queryParams.machine = machineId.map(item => item.code);
    }
    const res = await apiClient.get(`${configData.config.API_URL}/api/dashboard/report/all/responses/${groupId}`, {
      headers: {
        Authorization: `Bearer ${userData?.token}`,
      },
      params: queryParams,
    });
    setMachineScans(res);
  };

  const closeMultiSelect = (e) => {
    e.stopPropagation();
    setMachineId([]);
  };

  const Reload = async () => {
    setLoading(true);

    await fetchTagStats();
    await fetchMachineStats();
    await fetchResponseStats();
    await fetchMachineScans();
    await fetchNegativeResponses();
    await fetchNegativeResponsesGroupwise();
    setLoading(false);
  };
  useEffect(() => {
    if (!loading) Reload();
  }, [machineId]);
  useEffect(() => {
    if (!loading) Reload();
  }, [dates]);
  const RefreshPage = async () => {
    setLoading(true);
    await fetchGroupDetail();
    await fetchMachineList();
    setMachineId([]);
    setDates({ start: null, end: null });
    Reload();
  };
  useEffect(() => {
    if (!groupId.length) return;
    RefreshPage();
  }, [groupId]);

  const LoadStat = Array.from({ length: 4 }, (_, i) => (
    <div className="stat-box h-100" key={i}>
      <Skeleton width="4rem" height="4rem" baseColor="#ebebeb" highlightColor="#ffffff" />
      <div className="d-flex flex-column">
        <h6 className="mt-0">
          <Skeleton width="5rem" height="2rem" baseColor="#ebebeb" highlightColor="#ffffff" />{" "}
        </h6>
        <div className="d-flex gap-2">
          <h1>
            <Skeleton width="5rem" baseColor="#ebebeb" highlightColor="#ffffff" />{" "}
          </h1>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="container-fluid p-0">
      <Tab.Container id="my-tabs" activeKey={activeTab} defaultActiveKey="Dashboard">
        <Nav className="tabsproduct updated toptab" variant="tabs">
          <Nav.Item>
            <Nav.Link onClick={() => setActiveTab("Dashboard")} eventKey="Dashboard">
              Dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => setActiveTab("Groups")} eventKey="Groups">
              Groups
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="container-fluid admindashboard p-4 pt-4 bg-grey-main">
          <Tab.Content className="pt-3">
            <Tab.Pane eventKey="Dashboard">
              <div className="row align-items-center mt-3 mb-2">
                <div className="col-lg-6">
                  {userData.user.group.uuid !== groupId && (
                    <button className="backbtn cursor-pointer" title="Back" onClick={() => navigate(-1)}>
                      <BackIcon />
                      Back
                    </button>
                  )}
                </div>
                <div className="col-lg-6 d-flex align-items-center justify-content-end gap-3">
                  <div className="prime-select">
                    {machineId?.length > 0 && (
                      <span className="dselect" onClick={closeMultiSelect}>
                        <CloseIcon />
                      </span>
                    )}
                    <MultiSelect
                      value={machineId}
                      onChange={handleSortMachineList}
                      options={options}
                      optionLabel="name"
                      filter
                      placeholder="Select Machines"
                      maxSelectedLabels={3}
                      className="w-ful"
                    />
                  </div>
                  {/* <DateRangeSelector selected={filter} setSelected={setFilter} /> */}
                  <DateRangeFilter
                    dates={dates}
                    setDates={(newdates) => {
                      setDates(newdates);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-5">
                  <div className="userdash">
                    <div className="card cardesign welcomecard">
                      <div className="card-block d-flex align-items-center">
                        {loading ? (
                          <Skeleton width="70px" height="70px" circle baseColor="#ebebeb" highlightColor="#ffffff" />
                        ) : (
                          <>
                            <div className="profile-img">
                              {groupInfo?.avatar ? (
                                <img src={groupInfo?.avatar} alt="" referrerPolicy="no-referrer" />
                              ) : (
                                <div className="initials-placeholder">
                                  {groupInfo?.name ? getInitials(groupInfo?.name) : ""}
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        <div className="welcomediv ps-3">
                          <h6 className="m-0 p-0">
                            {loading ? (
                              <Skeleton width="15rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                            ) : (
                              groupInfo?.name
                            )}
                          </h6>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-7">
                  <div className="userdash">
                    <div className="redgreen">
                      <span>
                        <h6>
                          {loading ? (
                            <Skeleton width="5rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                          ) : (
                            "Machine Count"
                          )}
                        </h6>
                        <p>
                          {loading ? (
                            <Skeleton width="5rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                          ) : (
                            machineId.length? machineId.length : options?.length ?? 0
                          )}
                        </p>
                      </span>
                      <span>
                        <h6>
                          {loading ? (
                            <Skeleton width="5rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                          ) : (
                            "Total Scans"
                          )}
                        </h6>
                        <p>
                          {" "}
                          {loading ? (
                            <Skeleton width="5rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                          ) : (
                            machineScans?.total
                          )}{" "}
                        </p>
                      </span>
                      <span>
                        <h6>
                          {loading ? (
                            <Skeleton width="5rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                          ) : (
                            "Safe to operate Scans"
                          )}
                        </h6>
                        <p>
                          {loading ? (
                            <Skeleton width="5rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                          ) : (
                            machineScans?.correct
                          )}
                        </p>
                      </span>
                      <span>
                        <h6>
                          {loading ? (
                            <Skeleton width="5rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                          ) : (
                            "Caution, Trigger Scans"
                          )}
                        </h6>
                        <p>
                          {" "}
                          {loading ? (
                            <Skeleton width="5rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                          ) : (
                            machineScans?.incorrect
                          )}{" "}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Tag Stats Section */}
              <div className="row ">
                <h6 className="m-0 mt-2 mb-1" style={{ fontWeight: "600" }}>
                  {loading ? (
                    <Skeleton width="15rem" height="1.4rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                  ) : (
                    "Attributes"
                  )}
                </h6>
                <div className="stats-parent mt-0">
                  {loading
                    ? LoadStat
                    : tagStats.map((item, index) => (
                        <div className="stat-box h-100" key={index}>
                          <img src={item.icon} alt={item.tag} />
                          <div className="d-flex flex-column">
                            <h6 className="mt-0">
                              {item.tag.charAt(0).toUpperCase() + item.tag.slice(1).toLowerCase()}
                            </h6>
                            <div className="d-flex gap-2">
                              <h1>{item.count}</h1>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>

              {/* Response Chart and Machine Status Chart */}
              <div className="row">
                <div className="col-lg-6">
                  <div className="cardbox overflow-hidden custom-chart mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">
                        {loading ? (
                          <Skeleton width="15rem" height="1.4rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                        ) : (
                          "Machine's Response Status"
                        )}
                      </h5>
                    </div>
                    {loading ? null : <ResponseChart list={machineResponseStats} />}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="cardbox overflow-hidden custom-chart mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-3 mt-2">
                        {loading ? (
                          <Skeleton width="15rem" height="1.4rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                        ) : (
                          "Machine's Status & Action Triggers"
                        )}
                        <p className="info-additional">
                          {loading ? (
                            <Skeleton width="15rem" height="1.4rem" baseColor="#ebebeb" highlightColor="#ffffff" />
                          ) : (
                            <>As per recent scans during the selected period!</>
                          )}
                        </p>
                      </h5>
                    </div>
                    {loading ? null : <MachineChart machineStatus={machineStatus} />}
                  </div>
                </div>
              </div>

              <NegativeResponses questions={questions} loading={loading} />

              <GroupWiseReport list={list} loading={loading} />
            </Tab.Pane>
            <Tab.Pane eventKey="Groups">
              <GroupList setActiveTab={setActiveTab} />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
};

export default Dashboard;
