import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const GroupWiseReport = ({ list, loading }) => {

  if (loading) return null;

  return (
    <>
      <div className="machines-list">
        <div className="page-title">
          <h5>
            Group wise <span> Unhealthy State </span> report
          </h5>
        </div>
        <div className="table-responsive machinelist workflowtb mt-3" style={{ minHeight:"auto" }}>
          <table className="table align-middle table-nowrap mb-0">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">Groups</th>
                <th>Machine</th>
                <th className="text-center">Machine Owner</th>
                <th className="text-center">Unhealthy Records</th>
              </tr>
            </thead>
            <tbody>
               {list.map((item,index)=>{
                let _name = item.owner.name.split(" ").slice(0,2).map((e) => e[0]).join("").toUpperCase();
                return(
                    <tr>
                    <td className="text-center">{index+1}</td>
                    <td className="text-center">
                    <div className="details-tags attable">
                        <span>
                          {item.group.map((e) => {
                            return <p key={e.uuid}>{e.name}</p>;
                          })}
                        </span>
                      </div>
                      </td>
                    <td>   {" "}
                      <Link to={`/machine-info/${item.id}`}>
                        {item.machine}
                      </Link>{" "}</td>
                    
                    <td className="text-center">
                      <div className="details-tags attable">
                         <p>{item.owner.name}</p>
                         <p>{item.owner.email || item.owner.uid}</p>
                      </div>
                    </td>
                    <td className="text-center">
                        {item.unhealthyCount}
                    </td>
                  </tr>
                )
               })

               }

            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GroupWiseReport;
