import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { BackIcon, BreadCrumbIcon, CancelIcon, SearchesCIcon } from '../assets/Icons';
import { getInitials } from '../utils/util';
import { useSelector } from 'react-redux';
import axios from 'axios';

const Groups = ({ selectedGroups, setSelectedGroups, setParentGroupId, ParentGroupId, search = '', setSearch = () => { } }) => {
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    const [showMainGroup, setShowMainGroup] = useState(true);
    const [groupChildren, setGroupChildren] = useState([]);
    const [loading, setLoading] = useState(true);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [parentGroupName, setParentGroupName] = useState("");
    const [noGroupChildren, setNoGroupChildren] = useState(false);

    const staticTileCards = Array.from({ length: 4 }, (_, i) => (
        <div key={`static-tile-${i}`} className="tilecard p-0 mb-3" style={{ outline: 'none' }}>
            <div className='groupcardesign'>
                <div className='avatarimg p-0' style={{ outlineColor: '#ffffff00' }}>
                    <Skeleton count={1} circle height="100%" width="100%" className='m-0 p-0' />
                </div>
                <h6 className='groupname'> </h6>
                <div className="groupusercount d-flex flex-column gap-2">
                    <p><Skeleton width="10vw" className='my-1' /> </p>
                    <p><Skeleton width="10vw" className='my-1' /> </p>
                </div>
            </div>
        </div>
    ));

    const getGroupList = async (groupId, token) => {
        try {
            const { data } = await axios.get(`${configData.config.API_URL}/api/user/group/hierarchy/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setParentGroupId(data.data.data.uuid)
            setParentGroupName(data.data.data.name)
            setBreadcrumbs([{ name: data.data.data.name, uuid: data.data.data.uuid }]);
            let groupData = await fetchChildrenGroup(groupId);
            setGroupChildren(groupData);
            if (groupData.length === 0) setNoGroupChildren(true)
        } catch (error) {
            console.error("Error fetching group list:", error.message);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const fetchChildrenGroup = async (uuid) => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/user/group/list/${uuid}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token}`,
                    'Content-Type': 'application/json'
                }
            });
            return data.data;
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        getGroupList(userData.user.group.uuid, userData.token);
    }, [userData.user.group.uuid, userData.token]);

    const handleClickViewSubGroup = async (uuid, name) => {
        setLoading(true);
        let groupdata = await fetchChildrenGroup(uuid);
        setBreadcrumbs(prev => [...prev, { name, uuid }]);
        setGroupChildren(groupdata);
        setShowMainGroup(false);
        setLoading(false);
    };

    const handleBackGroupView = async () => {
        setLoading(true);
        let breadcrumbsCopy = [...breadcrumbs];

        if (breadcrumbsCopy.length > 1) {
            breadcrumbsCopy.pop();
            const lastIndexUUID = breadcrumbsCopy[breadcrumbsCopy.length - 1].uuid;
            let groupdata = await fetchChildrenGroup(lastIndexUUID);
            setGroupChildren(groupdata);
            setShowMainGroup(false);
        } else {
            setShowMainGroup(true);
            setGroupChildren([]);
        }
        setBreadcrumbs(breadcrumbsCopy);
        setLoading(false);
    };

    const handleBreadcrumbClick = async (breadcrumb, index) => {
        setLoading(true);
        const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
        setBreadcrumbs(newBreadcrumbs);

        if (index === 0) {
            setShowMainGroup(true);
            await getGroupList(userData.user.group.uuid, userData.token);
        } else {
            const lastBreadcrumb = newBreadcrumbs[newBreadcrumbs.length - 1];
            let groupdata = await fetchChildrenGroup(lastBreadcrumb.uuid);
            setGroupChildren(groupdata);
            setShowMainGroup(false);
        }

        setLoading(false);
    };

    const handleSelectGroup = (groupId, groupName) => {
        setSelectedGroups(prevSelectedGroups => {
            const isSelected = prevSelectedGroups.some(group => group.uuid === groupId);
            if (isSelected) {
                return prevSelectedGroups.filter(group => group.uuid !== groupId);
            } else {
                return [...prevSelectedGroups, { uuid: groupId, name: groupName }];
            }
        });
    };

    const handleDeselectGroup = (groupId) => {
        setSelectedGroups(prevSelectedGroups => {
            return prevSelectedGroups.filter(group => group.uuid !== groupId);
        });
    };


    const filteredGroupChildren = groupChildren.filter(group => group.name.toLowerCase().includes(search.toLowerCase()));

    return (
        <div>
            <div className='row'>
                <div className='col-lg-6'>
                    <p className='list-tag mt-4'> Select Group </p>
                </div>
                <div className='col-lg-6 d-flex align-items-center justify-content-end'>
                </div>
            </div>
            <div className={ noGroupChildren ? "mb-5" : " courses-parent usergrouplist group-parent" }> 
                <div className='group-title'>

                    {
                        noGroupChildren ? (
                            <p className='group-info'> No subgroups available. The machine will be assigned to the default group: <span> {parentGroupName} </span> </p>
                        ) : (
                            <>
                                <span>
                                    {breadcrumbs.length > 1 && (
                                        <button onClick={handleBackGroupView} className="backbtn cursor-pointer" title="Back">
                                            <BackIcon />
                                            Back
                                        </button>
                                    )}
                                    <div className='breadcrums'>
                                        {breadcrumbs.map((e, index) => (
                                            <React.Fragment key={e.uuid}>
                                                <p onClick={() => handleBreadcrumbClick(e, index)} className={index < breadcrumbs.length - 1 ? "breadcrumb-link" : "breadcrumb-link last"}>
                                                    {e.name}
                                                </p>
                                                {index < breadcrumbs.length - 1 && <span> <BreadCrumbIcon /> </span>}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </span>
                                <form className="app-search app-search1 d-flex justify-content-start me-2">
                                    <div className="position-relative">
                                        <SearchesCIcon />
                                        {search.length > 0 && <span className='searchiconcancel' onClick={() => setSearch('')}>
                                            <CancelIcon />
                                        </span>}
                                        <input
                                            type="text"
                                            name="searchName"
                                            className="form-control"
                                            placeholder="Search"
                                            autoComplete="off"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </div>
                                </form>
                            </>
                        )

                    }


                </div>

                {loading ? (
                    <div className="course-tiles mt-2">
                        {staticTileCards}
                    </div>
                ) : (
                    <div className='tile-parent'>
                        <div className="course-tiles mt-2">
                            {filteredGroupChildren.map((item) => (
                                <div className={`tilecard p-0 mb-3 ${selectedGroups.some(group => group.uuid === item.uuid) ? 'selected-group' : ''}`} key={item.uuid}>
                                    <div className='groupcardesign'>
                                        <div className='avatarimg'>
                                            {item.avatar ? (
                                                <img src={item.avatar} alt={`Avatar for ${item.name}`} title={item.name} referrerPolicy='no-referrer' />
                                            ) : (
                                                <span className='initial-small'>
                                                    {getInitials(item.name)}
                                                </span>
                                            )}
                                        </div>
                                        <h6 className='groupname'> {item.name} </h6>
                                        <div className="groupusercount">
                                            <p>{item.groups} Sub-Groups</p>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-2'>
                                            <span onClick={() => handleSelectGroup(item.uuid, item.name)} className='select-group'>
                                                {selectedGroups.some(group => group.uuid === item.uuid) ? "Selected" : "Select"}
                                            </span>
                                            {!selectedGroups.some(group => group.uuid === item.uuid) && item.groups > 0 && (
                                                <span onClick={() => handleClickViewSubGroup(item.uuid, item.name)} className='more-groups'>
                                                    Subgroups
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {!loading &&
                    <>
                        {selectedGroups.length > 0 && (
                            <div className='selected-groups'>
                                <p>Selected Groups : </p>
                                {selectedGroups.map(group => (
                                    <span className='group-id' key={group.uuid}>
                                        {group.name}
                                        <span onClick={() => handleDeselectGroup(group.uuid)}>
                                            <CancelIcon />
                                        </span>
                                    </span>
                                ))}
                            </div>
                        )}
                    </>}
            </div>
        </div>
    );
};

export default Groups;
