import React, { useEffect, useRef, useState } from "react";
import { NestedSelect } from "multi-nested-select";

const NestedMultiSelect = ({ groups }) => {
  const ref = useRef();
  const [data, setData] = useState([]);
  const [response, setResponse] = useState([]);

  const callbackFunction = (value) => {
    setResponse(value);
  };
  useEffect(() => {
    const updatedData = Array.isArray(groups) ? groups : [groups];
    setData(updatedData);
  }, [groups]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("sving fat");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div ref={ref}>
      <NestedSelect
        buttonContent="Want to save !"
        enableButton={true}
        state={true}
        width={450}
        height={200}
        leading={true}
        // chip={true}
        // limit={5}
        placeholderCtx={true}
        trailing={true}
        trailingIcon={true}
        inputClass="myCustom_text"
        continent={true}
        selectAllOption={true}
        dropDownClass="myCustom_dropbox"
        selectedValue={data}
        onViewmore={(v) => alert("viewed")}
        onChipDelete={(v) => alert("deleted")}
        onChange={(v) => console.log("okay", v)}
        callback={(val) => callbackFunction(val)}
      />
    </div>
  );
};

export default NestedMultiSelect;
