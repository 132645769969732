import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  groups: {},
};

const groupSlice = createSlice({
  name: "groupSlice",
  initialState,
  reducers: {
    saveGroupList: (state, action) => {
      state.groups = action.payload;
    },
  },
});

export const { saveGroupList } = groupSlice.actions;
export default groupSlice.reducer;
