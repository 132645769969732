import axios from "axios";

const apiClient = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const successResponseInterceptor = (response) => {
  return response?.data?.data;
};

const errorResponseInterceptor = (error) => {
  console.log(error?.response);
};

apiClient.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);

export default apiClient;
