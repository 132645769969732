import React, { useEffect, useState } from "react";
import { CSVIcon, DeleteIcon, DisableDeleteIcon, EditIcon, EnableDeleteIcon } from "../assets/Icons";
import AddCheckpoint from "./modals/AddCheckpoint";
import DeleteItem from "./modals/DeleteItem";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import QuestionCSV from "./modals/QuestionCSV";

const CheckList = ({ setQestionLength, setQuestionMapping }) => {
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const [addCheckpoint, setAddCheckpoint] = useState(false);
  const [deleteCheckpoint, setDeleteCheckpoint] = useState(false);
  const [selected, setSelected] = useState([]);
  const [deleteSingle, setDeleteSingle] = useState(false);
  const [editQuestion, seEditQuestion] = useState({});
  const [questions, setAllQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { uuid } = useParams();
  const [CSVModal, setCSVModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  const fetchQuestions = async () => {
    try {
      let { data } = await axios.get(`${configData.config.API_URL}/api/machine/questions/all/${uuid}`, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
          "Content-Type": "application/json",
        },
      });
      setAllQuestions(data.data);
      const mapping = {};
      data.data?.forEach((item) => {
        mapping[item.uuid] = item.title;
      })
      setQuestionMapping(mapping)
      setQestionLength(data.data.length);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
    setCSVModal(false);
    setProcessing(false);
  };

  const handleSelect = (id, checked) => {
    setSelected(checked ? [...selected, id] : selected.filter((e) => e !== id));
  };

  const handleSelectAll = () => {
    setSelected(selected.length === questions.length ? [] : questions.map((e) => e.uuid));
  };

  const handleDelete = (id) => {
    setSelected([id]);
    setDeleteSingle(true);
    setDeleteCheckpoint(true);
  };

  const handleEditQuestion = (elem, index) => {
    setAddCheckpoint(true);
    seEditQuestion({ active: true, data: elem, index });
  };

  useEffect(() => {
    setSelected(selected.length === questions.length ? questions.map((e) => e.uuid) : selected);
  }, [questions.length]);

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <>
      <div className="checklist">
        {!loading && (
          <>
            {questions.length > 0 && (
              <>
                <div className="list-head" style={{ height: "35px" }}>
                  <div className="form-check listchild">
                    {/* <>
                      <input
                        onChange={handleSelectAll}
                        className='form-check-input'
                        type='checkbox'
                        checked={selected.length === questions.length}
                        id='selectAllCheck'
                      /> 

                      <label className='form-check-label' htmlFor='selectAllCheck'>
                        {selected.length} / {questions.length}
                      </label>
                      <div className='svgbtn' style={{ marginTop: '-1px' }} onClick={() => { setDeleteCheckpoint(true); setDeleteSingle(false) }}>
                        {
                          selected.length > 0 ? <EnableDeleteIcon /> : <DisableDeleteIcon />
                        }
                      </div>
                    </> */}
                  </div>

                  {userData?.user?.permissions?.includes("MACHINE.QUESTIONS.ADD") && (
                    <div className="d-flex gap-3">
                      <button className="plain-btn icon" onClick={() => setCSVModal(true)}>
                        <CSVIcon />
                        Upload CSV
                      </button>
                      <button className="plain-btn" onClick={() => setAddCheckpoint(true)}>
                        Add Question
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {loading ? (
          <>
            <div className="list-body mt-3 p-0">
              <div className="table-responsive workflowtb mt-2">
                <table className="table align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th className="text-center">S.NO</th>
                      <th>Questions</th>
                      <th className="text-center">Tags</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <Skeleton width="3vw" height="1.65rem" />
                      </td>
                      <td>
                        {" "}
                        <Skeleton width="20vw" height="1.65rem" />{" "}
                      </td>
                      <td className="text-center">
                        {" "}
                        <Skeleton width="5vw" height="1.65rem" />{" "}
                      </td>
                      <td className="text-center">
                        {" "}
                        <Skeleton width="5vw" height="1.65rem" />{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        <Skeleton width="3vw" height="1.65rem" />
                      </td>
                      <td>
                        {" "}
                        <Skeleton width="20vw" height="1.65rem" />{" "}
                      </td>
                      <td className="text-center">
                        {" "}
                        <Skeleton width="5vw" height="1.65rem" />{" "}
                      </td>
                      <td className="text-center">
                        {" "}
                        <Skeleton width="5vw" height="1.65rem" />{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        <Skeleton width="3vw" height="1.65rem" />
                      </td>
                      <td>
                        {" "}
                        <Skeleton width="20vw" height="1.65rem" />{" "}
                      </td>
                      <td className="text-center">
                        {" "}
                        <Skeleton width="5vw" height="1.65rem" />{" "}
                      </td>
                      <td className="text-center">
                        {" "}
                        <Skeleton width="5vw" height="1.65rem" />{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <>
            {questions.length > 0 ? (
              <div className="list-body mt-3 p-0">
                <div className="table-responsive workflowtb mt-2">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th className="text-center">S.NO</th>
                        <th>Questions</th>
                        <th className="text-center">Tags</th>
                        {userData?.user?.permissions?.includes("MACHINE.QUESTIONS.ADD") && (
                          <th className="text-center">Actions</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {questions.length > 0 &&
                        questions.map((elem, index) => (
                          <tr key={elem.uuid}>
                            <td className="text-center">
                              <div className="d-flex align-items-center justify-content-center">
                                <div className="form-check listchild">
                                  {/* <input
                                  onChange={(e) => handleSelect(elem.uuid, e.target.checked)}
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={selected.includes(elem.uuid)}
                                  id={`check-${elem.uuid}`}
                                /> */}
                                  <label className="form-check-label" htmlFor={`check-${elem.uuid}`}>
                                    {index + 1}
                                  </label>
                                </div>
                              </div>
                            </td>
                            <td>{elem.title}</td>
                            <td>
                              <div className="details-tags attable">
                                <span>
                                  {elem.tags.length
                                    ? elem.tags.map((e) => (
                                        <p key={e.id} className="tag">
                                          {e.name}
                                        </p>
                                      ))
                                    : null}
                                </span>
                              </div>
                            </td>
                            {userData?.user?.permissions?.includes("MACHINE.QUESTIONS.ADD") && (
                              <td className="text-center">
                                <div className="action-btns">
                                  <span onClick={() => handleEditQuestion(elem, index)}>
                                    <EditIcon />
                                  </span>
                                  {/* <span onClick={() => handleDelete(elem.uuid)}>
                                  <DeleteIcon />
                                </span> */}
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <>
                {userData?.user?.permissions?.includes("MACHINE.QUESTIONS.ADD") ? (
                  <>
                    <div className="row">
                      <div className="px-4">
                        <div className="first-event">
                          No Question added yet!
                          <div className="d-flex gap-3">
                            <button className="plain-btn icon" onClick={() => setCSVModal(true)}>
                              <CSVIcon />
                              Upload CSV
                            </button>
                            <button className="plain-btn" onClick={() => setAddCheckpoint(true)}>
                              Add Question
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
      <AddCheckpoint
        addCheckpoint={addCheckpoint}
        setAddCheckpoint={setAddCheckpoint}
        fetchQuestions={fetchQuestions}
        size={questions.length}
        editQuestion={editQuestion}
        seEditQuestion={seEditQuestion}
      />
      <DeleteItem
        setSelected={setSelected}
        selected={selected}
        deleteCheckpoint={deleteCheckpoint}
        fetchQuestions={fetchQuestions}
        setDeleteCheckpoint={setDeleteCheckpoint}
        deleteSingle={deleteSingle}
      />
      <QuestionCSV
        fetchQuestions={fetchQuestions}
        machineId={uuid}
        CSVModal={CSVModal}
        setCSVModal={setCSVModal}
        setProcessing={setProcessing}
        processing={processing}
      />
    </>
  );
};

export default CheckList;
