import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    "group": {},
    "permissions": [],
    "roleAllocations": []
  },
  channelUsers: [],
  token: ""
}

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    saveUserData: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    removeUser: (state) => {
      state.user = {};
      state.token = "";
    },
    saveChannelUsers: (state, action) => {
      state.channelUsers = action.payload;
    }
  },

});

export const { saveUserData, removeUser, saveChannelUsers } = userSlice.actions;
export default userSlice.reducer;