import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import MachineList from "./MachineList";
import MachineInfo from "./MachineInfo";
import UserResponse from "./UserResponse";
import CreateMachine from "./CreateMachine";
import Dashboard from "./Dashboard";
import { useSelector } from "react-redux";

const AppRouter = () => {
  const userData = useSelector((state) => state.userSlice);
  const groupId = userData?.user?.group?.uuid;
  let defaultRoute = `/machine-list/view/${groupId}`;

  return (
    <Routes>
      <Route path="/machine-list/view/:groupId" element={<MachineList />} />
      <Route path="/machine-list/:type" element={<CreateMachine />} />
      <Route path="/machine-info/:uuid" element={<MachineInfo />} />
      <Route path="/user-response/:responseId/:machineId" element={<UserResponse />} />
      <Route path="/dashboard/:groupId" element={<Dashboard />} />
      <Route path="*" element={<Navigate to={defaultRoute} />} />
    </Routes>
  );
};

export default AppRouter;
