import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BackIcon, EditIcon, EXLDownloadIcon } from "../assets/Icons";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import CheckList from "./CheckList";
import AddItem from "./modals/AddItem";
import ResponseList from "./ResponseList";
import { getInitials } from "../utils/util.js";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import shadows from "@mui/material/styles/shadows.js";
import { defaultLogo } from "../env.js";
import { generateExcel } from "../utils/excel.js";
import moment from "moment";

const MachineInfo = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const [machineloading, setMachineLoading] = useState(true);
  const [tabActiveKey, setTabActiveKey] = useState("checklist");
  const [machineDetails, setMachineDetails] = useState({});
  const [addMachine, setAddMachine] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [responseLength, setResponseLenght] = useState(0);
  const [questionLength, setQestionLength] = useState(0);
  const [visibleGroups, setVisibleGroups] = useState([]);
  const [hiddenGroups, setHiddenGroups] = useState([]);
  const [userResponse, setUserResponse] = useState([]);
  const [questionMapping, setQuestionMapping] = useState({});

  const handleChangeActiveKey = (selectedTab) => {
    setTabActiveKey(selectedTab);
  };

  const fetchMachineDetails = async () => {
    try {
      let { data } = await axios.get(`${configData.config.API_URL}/api/machine/details/${uuid}`, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
          "Content-Type": "application/json",
        },
      });
      setMachineDetails(data.data);
      getGroupDetails(data.data.groups);
      setMachineLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getGroupDetails = async (groups) => {
    try {
      const {
        data: { data: groupData },
      } = await axios.post(
        `${configData.config.UNO_URL}/api/user/bulk/group/details`,
        { groupIds: groups },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setVisibleGroups(groupData.slice(0, 2));
      if (groupData.length > 2) setHiddenGroups(groupData.slice(2));
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSaveMachine = async (machineData, file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      if (file) {
        let { data } = await axios.post(
          `${configData.config.UNO_URL}/api/resource/machine-monitoring/attachment/${uuid}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        machineData.thumbnail = data.url;
      }

      await axios.put(`${configData.config.API_URL}/api/machine/update/${uuid}`, machineData, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
          "Content-Type": "application/json",
        },
      });
      setAddMachine(false);
      setLoadingBtn(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      fetchMachineDetails();
    }
  };

  const generateUserListExcel = () => {
    const headers = ["S.No.", "User", "Date & Time", "Health Status", ...Object.values(questionMapping || {})];
    const data = userResponse?.map((item, idx) => {
      const sheetData = {
        "S.No.": idx + 1,
        "User": item?.name,
        "Date & Time": `${moment(item?.createdAt).format("DD-MM-YYYY")} | ${moment(item?.createdAt).format("HH:mm")}`,
        "Health Status": item?.health,
      };
      item.data?.forEach((res) => {
        Object.keys(questionMapping || {}).forEach((que) => {
          if (que === res.questionId) {
            sheetData[questionMapping[que]] = res.answer?.toLowerCase() === "yes" ? 1 : 0;
            sheetData[res.questionId] = res.attachments[0];
          }
        });
      });

      return sheetData;
    });

    generateExcel("User_List_Response", data, headers.length - 4, headers);
  };

  useEffect(() => {
    fetchMachineDetails();
  }, [tabActiveKey]);

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        shadows,
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 300,
        boxShadow: "-1px 5px 5px #33333320",
        // border: '1px solid #dadde9',
      },
    })
  );

  return (
    <>
      <div className="container-fluid p-4">
        <div className="d-flex">
          <button className="backbtn cursor-pointer" title="Back" onClick={() => navigate("/machine-list")}>
            <BackIcon />
            Back
          </button>
        </div>

        <div className="row justify-content-between py-2 mt-0">
          <div className="col-md-3 d-flex align-items-center gap-3">
            <div className="info-title-image">
              <span className="Image-title">
                {machineDetails.thumbnail ? (
                  <img className="userimage-small" src={machineDetails.thumbnail} alt="" />
                ) : (
                  <span className="initial-small">{getInitials(machineDetails.name)}</span>
                )}
                {/* <h6 className='title'>{machineDetails.name}</h6> */}
                <div className="name-edit">
                  {machineloading ? (
                    <h4 className="m-name">
                      <Skeleton width="13rem" height="1.3rem" />
                    </h4>
                  ) : (
                    <>
                      <h4 className="m-name">{machineDetails.name}</h4>
                      <span onClick={() => navigate(`/machine-list/${uuid}`)}>
                        {" "}
                        <EditIcon />{" "}
                      </span>
                    </>
                  )}
                </div>
              </span>
              <div className="machine-stats">
                <span>
                  <h5>{responseLength}</h5>
                  <p>Responses</p>
                </span>
                <span>
                  <h5>{questionLength}</h5>
                  <p>Questions</p>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-9 d-flex align-items-center gap-3">
            <div className="info-title-image for-details">
              <div className="details-tags main mt-0 px-4">
                <div className="d-flex align-items-center gap-5 justify-content-start">
                  <span className="info-stats">
                    Serial Number :
                    {machineloading ? (
                      <span className=" info-stats mt-1">
                        <Skeleton width="8rem" height="1.3rem" baseColor="#ca373c20" highlightColor="#ca373c50" />
                      </span>
                    ) : (
                      <p>{machineDetails?.data?.serialNo ? machineDetails.data.serialNo : "NA"}</p>
                    )}
                  </span>
                  <span className="info-stats">
                    Machine ID :
                    {machineloading ? (
                      <span className=" info-stats mt-1">
                        <Skeleton width="8rem" height="1.3rem" baseColor="#ca373c20" highlightColor="#ca373c50" />
                      </span>
                    ) : (
                      <p>{machineDetails?.data?.machineId}</p>
                    )}
                  </span>
                </div>

                <span className="info-stats">
                  {" "}
                  Group :
                  {machineloading ? (
                    <span className="d-flex align-items-center justify-content-start gap-2">
                      <Skeleton width="10rem" height="1.6rem" baseColor="#ca373c20" highlightColor="#ca373c50" />
                      <Skeleton width="10rem" height="1.6rem" baseColor="#ca373c20" highlightColor="#ca373c50" />
                    </span>
                  ) : (
                    <div className="d-flex align-items-center justify-content-start gap-2">
                      {visibleGroups.length &&
                        visibleGroups.map((e) => {
                          return (
                            <div className="nameemail" key={e.uuid}>
                              {e.avatar ? (
                                <img src={e.avatar} />
                              ) : (
                                <span className="initial-small extra">{getInitials(e.name)}</span>
                              )}
                              <p> {e.name}</p>
                            </div>
                          );
                        })}
                      {hiddenGroups.length > 0 && (
                        <HtmlTooltip
                          title={
                            <>
                              <div className="tooltip-parent">
                                {hiddenGroups.map((e) => {
                                  return (
                                    <div className="nameemail" key={e.uuid}>
                                      {e.avatar ? (
                                        <img src={e.avatar} />
                                      ) : (
                                        <span className="initial-small extra">{getInitials(e.name)}</span>
                                      )}
                                      <p> {e.name}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          }
                        >
                          <p className="extra-item"> +{hiddenGroups.length} </p>
                        </HtmlTooltip>
                      )}
                    </div>
                  )}
                </span>

                <div className="details-tags mt-1">
                  Tags :
                  {machineloading ? (
                    <span className="mt-1">
                      <Skeleton width="4rem" baseColor="#ca373c20" highlightColor="#ca373c50" />
                      <Skeleton width="4rem" baseColor="#ca373c20" highlightColor="#ca373c50" />
                    </span>
                  ) : (
                    <span className="mt-1">
                      {machineDetails?.tags?.length
                        ? machineDetails.tags.map((e) => (
                            <p key={e.id} className="tag">
                              {e.name}
                            </p>
                          ))
                        : ""}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Tab.Container activeKey={tabActiveKey} onSelect={handleChangeActiveKey}>
          <div className="row mt-3">
            <div className="tabanddown">
              <Nav variant="pills" className="module-tab">
                {userData?.user?.permissions?.includes("MACHINE.QUESTIONS") && (
                  <Nav.Item>
                    <Nav.Link eventKey="checklist">Questionnaire</Nav.Link>
                  </Nav.Item>
                )}
                {userData?.user?.permissions?.includes("MACHINE.RESPONSES") && (
                  <Nav.Item>
                    <Nav.Link eventKey="response">User Response</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
              {tabActiveKey === "response" && (
                <div className="d-flex gap-3">
                  <button className="plain-btn icon" onClick={generateUserListExcel} title="Download Excel">
                    <EXLDownloadIcon />
                  </button>
                </div>
              )}
            </div>
          </div>
          <Tab.Content className="mt-3 mb-4">
            {userData?.user?.permissions?.includes("MACHINE.QUESTIONS.VIEW") && (
              <Tab.Pane eventKey="checklist">
                <CheckList setQestionLength={setQestionLength} setQuestionMapping={setQuestionMapping} />
              </Tab.Pane>
            )}
            {userData?.user?.permissions?.includes("MACHINE.RESPONSES") && (
              <Tab.Pane eventKey="response">
                <ResponseList setResponseLenght={setResponseLenght} setUserResponse={setUserResponse} />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
      </div>
      {addMachine && (
        <AddItem
          addMachine={addMachine}
          setAddMachine={setAddMachine}
          onSaveMachine={onSaveMachine}
          machineDetails={machineDetails}
          editMode={true}
          loadingBtn={loadingBtn}
          setLoadingBtn={setLoadingBtn}
        />
      )}
    </>
  );
};

export default MachineInfo;
