import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";

const DropdownBox = ({
  placeholder,
  value,
  setValue,
  options,
  handleSortMachineList,
  multiple = false,
}) => {
  const handleChange = (e) => {
    handleSortMachineList(e.value);
    setValue(e.value);
  };

  return (
    <div className="card flex justify-content-center prime-select">
      <Dropdown
        value={value}
        options={options}
        optionLabel="label"
        placeholder={placeholder}
        className="w-full md:w-14rem"
        style={{ minWidth: "10rem" }}
        onChange={(e) => handleChange(e)}
        multiple={multiple}
      />
    </div>
  );
};

export default DropdownBox;
