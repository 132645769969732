import React from 'react'
import { useSelector } from 'react-redux';
import { SideNav, SimApps } from '../../assets/Icons';
import { defaultLogo, applicationId } from '../../env';
import jwtEncode from 'jwt-encode';
import axios from 'axios';

const Header = ({ sideBar, setSideBar }) => {
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector(state => state.userSlice);
    const nameParts = userData?.user?.name?.split(' ');
    const applications = useSelector(state => state.applicationSlice).apps;
    const placeholder = nameParts?.length > 0 ? nameParts?.length >= 2 ? `${nameParts[0][0]}${nameParts[1][0]}` : `${nameParts[0][0]}` : "";

    const logOut = async () => {
        localStorage.clear();
        sessionStorage.clear();
        await axios.post(`${configData?.config?.UNO_URL}/api/auth/logout`, {}, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        });

        window.location.href = `${configData.config.redirectUri}`;
    }

    const redirectApplication = (link) => {
        if (userData?.token && link) {
            const payload = { token: userData?.token };
            const token = jwtEncode(payload, "");

            if (link.includes("apps.simulanis.io")) {
                const newUrl = `${link}`;
                window.location.href = newUrl;

            } else {
                const newUrl = `${link}?referrer=${token}`;
                window.location.href = newUrl;
            }

        }
    }

    return (
        <>
            <header>
                <div className='sidetoggle'>
                    <div
                        className={sideBar ? "ps-1 activebar sidesvgdiv" : " sidesvgdiv ps-1"}
                        onClick={() => {
                            setSideBar(!sideBar);
                            if (sideBar) {
                                document.body.classList.add('body-lock');
                            } else {
                                document.body.classList.remove('body-lock');
                            }
                        }}
                        style={{ width: "25px", height: "25px" }}>
                        <SideNav />
                    </div>
                    <div className='mainlogo LOGOHERE'>
                        <img
                            src={configData.config.logo}
                            onError={(e) => {
                                e.target.src = defaultLogo;
                                e.onerror = null;
                            }}
                            referrerPolicy={'no-referrer'}
                            alt=''
                        />
                        <p>{configData.config.name}</p>
                    </div>
                </div>
                <div className='leftmain'>
                    <div className='profile'>
                        <div className="dropdown profileavatar">
                            <p className='d-none d-md-block'>{userData?.user?.name || null}</p>

                            <button className="btn btn-secondary dropdown-toggle themetoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className='useravatar'>
                                    {userData?.user?.avatar?.length ?
                                        <img src={userData?.user?.avatar} alt="Profile Image" title={userData?.user?.name || null} className="profilepik" referrerPolicy={'no-referrer'} />
                                        :
                                        <div className="avatar-placeholder">{placeholder.toUpperCase()}</div>
                                    }
                                </div>
                            </button>

                            <div className="dropdown-menu themehere popupon">
                                <div className="profilemenuhead" tabIndex="0">
                                    {userData?.user?.avatar?.length ?
                                        <img src={userData?.user?.avatar} alt="Profile Image" title={userData?.user?.name || null} className="profilepik" referrerPolicy={'no-referrer'} />
                                        :
                                        <div className="avatar-placeholder">{placeholder.toUpperCase()}</div>
                                    }
                                    <div className="profilemenudata">
                                        <p>{userData?.user?.name || null}</p>
                                        <p>{userData?.user?.email || null}</p>
                                    </div>
                                </div>
                                <p className="logoutbtn" onClick={logOut} >Log out</p>
                            </div>
                        </div>

                        <div className="dropdown profileavatar appnavigator">
                            <button className="btn btn-secondary dropdown-toggle themetoggle " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <SimApps />
                            </button>

                            <div className="dropdown-menu themehere popupon">
                              <div className='menu-long' >
                                <div className={applications.length > 2 ? 'menubg extraspace' : 'menubg'}>
                                    {applications.map((e, i) => {
                                        // if( i >= 1) return null
                                        if (e.uuid === applicationId) return null;
                                        return <div onClick={() => redirectApplication(e.channel?.origins[0]?.url)} key={e.uuid} className='appshere'>
                                            <img
                                                src={e.icon || defaultLogo}
                                                onError={(e) => {
                                                    e.target.src = { defaultLogo };
                                                    e.onerror = null;
                                                }}
                                                alt=''
                                                referrerPolicy={'no-referrer'}
                                            />
                                            <p>{e.name}</p>
                                        </div>
                                    })}

                                </div>
                              </div>
                            </div>
                        </div>

                    </div>
                </div>

            </header>
        </>
    )
}

export default Header
