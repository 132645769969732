import React, { useEffect, useState } from "react";
import Tables from "./Tables";
import SearchBox from "./common/SearchBox";
import DropdownBox from "./common/DropdownBox";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AddItem from "./modals/AddItem";

const tableHeaders = ["SNo", "Machine Name", "Serial Number", "Model Number", "QR Code"];

const options = [
  { label: "Name (A-Z)", value: "ascName" },
  { label: "Name (Z-A)", value: "descName" },
  { label: "Latest", value: "ascTime" },
  { label: "Oldest", value: "descTime" },
];

const MachineList = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const [machineList, setMachineList] = useState([]);
  const [addMachine, setAddMachine] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [value, setValue] = useState("ascTime");

  const handleSortMachineList = (value) => {
    let sortedList = [...machineList];
    if (value === "ascTime") {
      sortedList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (value === "descTime") {
      sortedList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    } else if (value === "ascName") {
      sortedList.sort((a, b) => a.name.localeCompare(b.name));
    } else if (value === "descName") {
      sortedList.sort((a, b) => b.name.localeCompare(a.name));
    }
    setMachineList(sortedList);
  };

  let fetchAllMachines = async () => {
    try {
      let { data } = await axios.get(`${configData.config.API_URL}/api/machine/all/${groupId}`, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
          "Content-Type": "application/json",
        },
      });
      setMachineList(data.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSaveMachine = async (machineData, file) => {
    const postData = {
      name: machineData.name,
      machineId: machineData.machineId,
      serialNo: machineData.serialNo ? machineData.serialNo : null,
      tags: machineData.tags,
    };

    try {
      let response = await axios.post(`${configData.config.API_URL}/api/machine/add`, postData, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
          "Content-Type": "application/json",
        },
      });

      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        let { data } = await axios.post(
          `${configData.config.UNO_URL}/api/resource/machine-monitoring/attachment/${response.data.machineId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        postData.thumbnail = data.url;
      }

      await axios.put(
        `${configData.config.API_URL}/api/machine/update/${response.data.machineId}`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setAddMachine(false);
      setLoadingBtn(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      fetchAllMachines();
    }
  };

  useEffect(() => {
    fetchAllMachines();
  }, []);

  const loadBox = 4;
  const tableList = [];

  for (let i = 0; i < loadBox; i++) {
    tableList.push(
      <tr key={i}>
        <td className="text-center">
          <Skeleton width="3vw" height="1.65rem" />
        </td>
        <td>
          <Skeleton width="15vw" height="1.65rem" />
        </td>
        <td className="text-center">
          <Skeleton width="10vw" height="1.65rem" />
        </td>
        <td className="text-center">
          <Skeleton width="10vw" height="1.65rem" />
        </td>
        <td className="text-center">
          <Skeleton width="3vw" height="1.65rem" />
        </td>
        <td className="text-center">
          <Skeleton width="5rem" height="5rem" />
        </td>
      </tr>
    );
  }

  return (
    <>
      <div className="machines-list">
        <div className="page-title">
          <h5>Machines</h5>
          {!loading && (
            <>
              {machineList.length > 0 && userData?.user?.permissions?.includes("MACHINE.ADD") ? (
                <button className="plain-btn" onClick={() => navigate(`/machine-list/create`)}>
                  Add New Machine
                </button>
              ) : null}
              {/* {machineList.length > 0 && userData?.user?.permissions?.includes("MACHINE.ADD") ?
                <button className='plain-btn' onClick={() => setAddMachine(true)}>Add New Machine</button> : null} */}
            </>
          )}
          {/* <button className='plain-btn' onClick={()=> navigate(`/machine-list/create`)}>Add Machine</button>  */}
        </div>
        <section className="px-4">
          {!loading ? (
            <>
              {machineList.length > 0 ? (
                <>
                  <div className="tags-sort my-3">
                    <SearchBox search={search} setSearch={setSearch} />
                    <DropdownBox
                      value={value}
                      setValue={setValue}
                      options={options}
                      handleSortMachineList={handleSortMachineList}
                    />
                  </div>
                  <Tables
                    search={search}
                    tableHeaders={tableHeaders}
                    data={machineList}
                    fetchAllMachines={fetchAllMachines}
                  />
                </>
              ) : (
                <div className="row">
                  <div className="px-4">
                    <div className="first-event">
                      No Machine Found!
                      {userData?.user?.permissions?.includes("MACHINE.ADD") && (
                        <button
                          className="plain-btn"
                          onClick={() => navigate(`/machine-list/create`)}>
                          Add New Machine
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <Skeleton width="15vw" height="40px" baseColor="#ffffff99" />
                <Skeleton width="15vw" height="40px" baseColor="#ffffff99" />
              </div>
              <div className="table-responsive machinelist workflowtb mt-3">
                <table className="table align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th className="text-center">SNo</th>
                      <th>Machine Name</th>
                      <th className="text-center">Machine Id</th>
                      <th className="text-center">Tags</th>
                      <th className="text-center">Visibility</th>
                      <th className="text-center">QR Code</th>
                    </tr>
                  </thead>
                  <tbody>{tableList}</tbody>
                </table>
              </div>
            </>
          )}
        </section>
      </div>

      {
        <AddItem
          addMachine={addMachine}
          setAddMachine={setAddMachine}
          onSaveMachine={onSaveMachine}
          loadingBtn={loadingBtn}
          setLoadingBtn={setLoadingBtn}
        />
      }
    </>
  );
};

export default MachineList;
