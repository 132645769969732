import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import useWindowDimensions from '../../utils/useWindowDimensions';

const ResponseChart = ({ list }) => {
    const [chartData, setChartData] = useState({
        series: [],
        options: {},
    });

    let { width } = useWindowDimensions();

    useEffect(() => {
        if (list) {
            setChartData({
                series: [
                    {
                        name: 'Positive Response',
                        data: list.map((e) => e.positive),
                    },
                    {
                        name: 'Negative Response',
                        data: list.map((e) => e.negative),
                    },
                ],
                options: {
                    chart: {
                        type: 'bar',
                        height: 300,
                        toolbar: {
                            show: false,
                        },
                    },
                    colors: [ '#06D001', '#ff0000'],
                    plotOptions: {
                        bar: {
                            columnWidth: '30%',
                            borderRadius: 3,
                            borderWidth: 1,
                            // horizontal: true,
                        },
                    },
                    stroke: {
                        show: true,
                        width: 4,
                        colors: ['transparent'],
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        categories: list.map((e) => e.tagName.charAt(0).toUpperCase() + e.tagName.slice(1).toLowerCase()),
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetY: 0,
                        offsetX: -35,
                    },
                },
            });
        }
    }, [list]);

    if (!list) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div id="chart">
                <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={ width > 1700 ? 382 : 282 } ></ReactApexChart>
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default ResponseChart;