import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { CloseIcon } from '../../assets/Icons';

export const ExpandQR = ({ showQR, setShowQR }) => {

  return (

    <Modal show={showQR.active} size="md" centered onHide={() => setShowQR({ ...showQR, active: false })}>
      <Modal.Header className="modalheader">
        {showQR.machineName}
        <span onClick={() => setShowQR({ ...showQR, active: false })}>
          <CloseIcon />
        </span>
      </Modal.Header>
      <Modal.Body className="m-auto">
        <img src={showQR.qr} alt='' style={{ width:"20rem" }} />
      </Modal.Body>

    </Modal>

  )
}
