import React, { useEffect, useState } from 'react'
import Header from './Header'
import SideBar from './SideBar';
import { myApplicationList } from '../../Store/slice/applicationSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

const DefaultLayout = ({ sideBar, setSideBar }) => {
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector(state => state.userSlice);
  const dispatch = useDispatch();

  const fetchApplication = async (token, config) => {
    try {
      var { data } = await axios.get(`${configData.config.UNO_URL}/api/application/mine`, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      dispatch(myApplicationList(data.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchApplication();
  }, [])

  return (
    <>
      <Header sideBar={sideBar} setSideBar={setSideBar} />
      <SideBar sideBar={sideBar} setSideBar={setSideBar} />

    </>
  )
}

export default DefaultLayout
