import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CSVIcon, CloseIcon } from '../../assets/Icons';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import Papa from 'papaparse';
import { useSelector } from 'react-redux';
import axios from 'axios';

const QuestionCSV = ({ fetchQuestions, machineId, CSVModal, setCSVModal, processing, setProcessing }) => {
  const userData = useSelector(state => state.userSlice);
  const configData = useSelector(state => state.configSlice);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(false);

  const onDrop = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  const handleSave = async () => {
    if (!uploadedFile) return;

    setProcessing(true);

    try {
      const result = await new Promise((resolve, reject) => {
        Papa.parse(uploadedFile, {
          header: true,
          complete: (result) => resolve(result),
          error: (error) => reject(error),
        });
      });

      const jsonData = result.data
        .filter(row => row.title)
        .map(row => ({
          title: row.title.trim(),
          tags: row.tags ? row.tags.trim().split(',').map(tag => tag.trim()) : [],
          machineId: machineId,
          options: [
            {
              uuid: uuidv4(),
              option: "Yes",
              isCorrect: true
            },
            {
              uuid: uuidv4(),
              option: "No"
            }
          ]
        }));

      if (jsonData.length) {
        await saveQuestions(jsonData);
        setError(false)
        setUploadedFile(null);
      } else {
        setError(true)
        setCSVModal(true);
        setUploadedFile(null);
      }
    } catch (error) {
      console.error('Error parsing or saving questions:', error);
    }
    setProcessing(false)
  };

  const saveQuestions = async (json) => {
    try {
      await axios.post(
        `${configData.config.API_URL}/api/machine/questions/multiple`,
        { questions: json },
        {
          headers: {
            'Authorization': `Bearer ${userData.token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setUploadedFile(null)
    } catch (error) {
      console.error('Error saving questions:', error);
      throw error;
    } finally {
      setError(false)
      fetchQuestions();
    }
  };
  const handleDownload = () => {
    const csvData = [
      ['title', 'tags'],
      ['Xyz', ''],
    ];

    const csvContent = csvData.map(e => e.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement("a");

    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "sample.csv");

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };

  return (
    <Modal show={CSVModal} size="lg" centered onHide={() => setCSVModal(false)}>
      <Modal.Header className="modalheader">
        Add Questions List
        <span onClick={() => setCSVModal(false)}>
          <CloseIcon />
        </span>
      </Modal.Header>
      <Modal.Body className="my-2">
        <div className="row px-3">
          <div {...getRootProps()} className="dropzone-csv">
            <input {...getInputProps()} />
            <span>
              <CSVIcon />
              {
                uploadedFile ? (
                  <>
                    {
                      uploadedFile.type === 'text/csv' || uploadedFile.type === 'text/xlsx' ? (
                        <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                          <p className='d-flex align-item-center justify-content-center m-0'>{uploadedFile.name}  <span className='text-success ps-2'>  Added!</span></p>
                          <p className='d-flex align-item-center justify-content-center m-0'>Click on the "Save" button to upload the file, or select another one</p>
                        </div>
                      ) : (
                        <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                          <p className='d-flex align-item-center justify-content-center m-0'>File format not supported</p>
                          <p className='d-flex align-item-center justify-content-center m-0'>Drag 'n' drop a CSV file here, or click to select another one</p>
                        </div>
                      )
                    }
                  </>
                ) :
                  <p>Drag 'n' drop a CSV file here, or click to select one</p>
              }
            </span>
          </div>
          {/* ' */}
          <div className='col-12 csv-info'>
            <p>Required Fields<span>*</span></p>
            <p>title, tags</p>
            {
              error &&
              <p className='error'>
                Error CSV file must include "title" and "tags" fields.
              </p>
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="footerbtn csv-modal">
        {/* <a href={QuestionSampleCSV} download className="template-down">
          <span>Download Group CSV Templates</span>
        </a> */}
        <div>
          <p onClick={handleDownload} className="template-down m-0">Download Question List CSV Templates</p>
        </div>
        <div className="d-flex">
          <button className="cancel" variant="secondary" onClick={() => { setCSVModal(false); setUploadedFile(null) }}>
            Cancel
          </button>
          {
            processing ? (
              <button className='loading save'>
                <svg viewBox="25 25 50 50">
                  <circle r="20" cy="50" cx="50"></circle>
                </svg>
              </button>
            ) : (
              <>
                {
                  uploadedFile && (uploadedFile.type === 'text/csv' || uploadedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ?
                    <button className='save' onClick={handleSave}>Save</button> :
                    ""
                }
              </>
            )
          }
        </div>
      </Modal.Footer>
    </Modal >
  );
};

export default QuestionCSV;
