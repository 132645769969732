import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    locations: [],
    makes: [],
    questionTypes: [],
    categories: {}
}

const tagsSlice = createSlice({
    name: "tagsSlice",
    initialState,
    reducers: {

        saveTags: (state, action) => {
            var categories = {}
            for (var i of action.payload) {
                categories[i.name] = i.id;
            }
            state.locations = action.payload.filter((e) => e.name === 'location')[0]?.tags || [];
            state.makes = action.payload.filter((e) => e.name === 'make')[0]?.tags || [];
            state.questionTypes = action.payload.filter((e) => e.name === 'questionType')[0]?.tags || [];
            state.categories = categories;
        },
    }
});

export const { saveTags } = tagsSlice.actions;
export default tagsSlice.reducer;