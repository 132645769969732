import React, { useEffect, useState } from 'react'
import Attachments from './modals/Attachments'
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom'

const UserResponseList = ({ loading, data, fetchQestions }) => {
  const [showAttachments, setShowAttachments] = useState(false)
  const [attachment, setAttachment] = useState([])

  const handleView = (data) => {
    setAttachment(data)
    setShowAttachments(true)
  }

  useEffect(() => {
    fetchQestions();
  }, []);

  return (
    <>
      <div className='table-responsive workflowtb mt-3'>
        <table className='table align-middle table-nowrap mb-0'>
          <thead>
            <tr>
              <th className='text-center'>S.No.</th>
              <th>Question</th>
              <th className='text-center'>Response</th>
              <th className='text-center'>Attachments</th>
            </tr>
          </thead>
          <tbody>
            {loading ?
              <>
                <tr>
                  <td className='text-center'><Skeleton width="5vw" height="1.4rem" /></td>
                  <td><Skeleton width="20vw" height="1.4rem" /></td>
                  <td className='text-center'><Skeleton width="5vw" height="1.4rem" /></td>
                  <td className='text-center'><Skeleton width="5vw" height="1.4rem" /></td>
                </tr>
                <tr>
                  <td className='text-center'><Skeleton width="5vw" height="1.4rem" /></td>
                  <td><Skeleton width="20vw" height="1.4rem" /></td>
                  <td className='text-center'><Skeleton width="5vw" height="1.4rem" /></td>
                  <td className='text-center'><Skeleton width="5vw" height="1.4rem" /></td>
                </tr>
                <tr>
                  <td className='text-center'><Skeleton width="5vw" height="1.4rem" /></td>
                  <td><Skeleton width="20vw" height="1.4rem" /></td>
                  <td className='text-center'><Skeleton width="5vw" height="1.4rem" /></td>
                  <td className='text-center'><Skeleton width="5vw" height="1.4rem" /></td>
                </tr>
              </>
              :
              <>
                {
                  data?.length > 0 && data.map((e, i) => {
                    return (
                      <tr key={i + 1}>
                        <td className='text-center'> {i + 1} </td>
                        <td>
                          <div className='ques-comment'>
                            <span>{e.question}</span>
                            {
                              e.comment &&
                              <span className='comment'> <span> Comment : </span>{e.comment}</span>
                            }
                          </div>
                        </td>

                        {
                          e.answer === "Yes" ? (
                            <td className='text-center good'>
                              <span className='good'>
                                {e.answer}
                              </span>
                            </td>
                          ) : (
                            <td className='text-center bad'>
                              <span className='bad'>
                                {e.answer}
                              </span>
                            </td>
                          )
                        }

                        <td className='text-center'>
                          {
                            e?.attachments?.length > 0 ?
                              <Link onClick={() => handleView(e.attachments)}>View</Link>
                              : <div>NA</div>
                          }
                        </td>
                      </tr>)
                  })
                }
              </>}
          </tbody>
        </table>
      </div>

      <Attachments showAttachments={showAttachments} setShowAttachments={setShowAttachments} attachment={attachment} />


    </>
  )
}

export default UserResponseList