import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  config: {},
  loading: true,
  error: true,
};

const configSlice = createSlice({
  name: "configSlice",
  initialState,
  reducers: {
    saveConfig: (state, action) => {
      state.config = action.payload.data;
      // state.config = { ...action.payload.data, API_URL: "http://localhost:4002" };
      state.loading = false;
      state.error = false;
    },
    removeConfig: (state, action) => {
      state.config = {};
      state.loading = true;
      state.error = true;
    },
  },
});

export const { saveConfig, removeConfig } = configSlice.actions;
export default configSlice.reducer;
