import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackIcon, EXLDownloadIcon, RightArrowIcon } from "../assets/Icons";
import UserResponseList from "./UserResponseList";
import { useSelector } from "react-redux";
import axios from "axios";
// import { generateExcel } from "../utils/excel";

const UserResponse = () => {
  const navigate = useNavigate();

  const { responseId, machineId } = useParams();

  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchQestions = async () => {
    try {
      let { data } = await axios.get(
        `${configData.config.API_URL}/api/machine/quiz/response/${responseId}/${machineId}`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setQuestions(data.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  // const generateUserResponseExcel = () => {
  //     const tableData = questions?.data?.map((item, idx) => ({
  //       "S.No.": idx + 1,
  //       Question: item?.question,
  //       Response: item?.answer,
  //       Attachments: item?.attachments?.join(","),
  //     }));
  //     generateExcel("User_Response", tableData);
  // };

  useEffect(() => {
    fetchQestions();
  }, []);
  return (
    <div className="container-fluid p-4">
      <div className="d-flex gap-2">
        <button className="backbtn cursor-pointer" title="Back" onClick={() => navigate(-1)}>
          <BackIcon />
          Back
        </button>
        <div className="breadcrums m-0">
          <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
            {questions.machineName}
          </span>
          <span>
            {" "}
            <RightArrowIcon />{" "}
          </span>
          <span>{questions.name}</span>
        </div>
      </div>
      <div className="d-flex gap-3" style={{ justifyContent: "flex-end" }}>
        {/* <button className="plain-btn icon" title="Download Excel" onClick={generateUserResponseExcel}>
          <EXLDownloadIcon />
        </button> */}
      </div> 
      <UserResponseList loading={loading} data={questions.data} fetchQestions={fetchQestions} />
    </div>
  );
};

export default UserResponse;
